export default {
    admission: "Admission and Registration at International Universities",
    pleased: "We are pleased to offer students guidance and administration of admission and registration at international universities, in accordance with simple and integrated service standards to suit the desire of our students interested in joining universities and academic institutes in worldwide.",
    see_programmes: "See Programs",
    our_story: "Our Story",
    story_details: "Ambitious team higher education agency panel is the first and the final destination for the students who are seeking enrollment in the universities and institutes in Malaysia through an official agent, Ambitious Team is one of the most recognized facilitators in academic, civil and social obstacles and challenges faced by international students in Malaysia.",
    advantages: "Advantages",
    adv1_title: "Integrated Services",
    adv1_detail: "We provide integrated services for students through our portal, which includes academic, social and civil services.",
    adv2_title: "Officially Accredited Agency",
    adv2_detail: "We provide integrated services for students through our portal, which includes academic, social and civil services.",
    adv3_title: "7 YEARS",
    adv3_detail: "We successfully managed to help many students to enroll in accredited universities and institutes in Malaysia.",
    adv4_title: "24/7 Support",
    adv4_detail: "The ambitious team is available in three major cities in Malaysia and has a high level of experience in solving social and official academic obstacles.",
    our_services: "Our Services",
    service1_title: "Admission and Registration",
    service1_detail: "The admission and registration process are administered by nominating the appropriate majors or matching the preference sent by the applicants via the Admission and Registration form. Our recommendations for universities and institutes are subject to the academic accreditation of the university internationally and locally in the applicants place of residence.",
    service2_title: "Accommodation",
    service2_detail: "After completion of the admission process and visa procedures for Malaysia by the follow-up activities, coordination with the Ambition Team by the date of arrival of the student or applicant is advised to be picked up from the airport by an agent from the university applied to and a member from the Ambitious Team, in order to complete the necessary procedures to enter Malaysia, and transportation of the student to the place of residence.",

    contact_us: "Contact Us",
    send_message: "Send message",
    subject: "Subject",
    name: "Name",
    email: "Email",
    message: "Message",

    story_head:"ATeamSpot is the Malaysian Study Choice Platform.",
    our_mission:"Our mission: ‘Empowering the world to choose education’.",
    how:"How?",
    how_details:"Ambitious team higher education agency panel is the first and the final destination for the students who are seeking enrollment in the universities and institutes in Malaysia through an official agent, Ambitious Team is one of the most recognized facilitators in academic, civil and social obstacles and challenges faced by international students in Malaysia.",
    why:"Why?",
    why_details:"We consider international study experience extremely valuable to students’ development. Through our portals, we try to fill the information gap that hinders students from finding and starting their international studies. On this mission, there’s still a lot of work to be done, but we are working enthusiastically and welcome cooperation with students and universities.",

    our_team:"Our Team",
    manager:"Manager",
    developer:"Developer",
    marketing:"Marketing",
    academic_advisor:"Academic advisor",
    personal_relations:"Personal relations",
    student_director:"Student director",

    whatsapp: "I have a WhatsApp account:",
    nationality: "Nationality:",
    academic_level: "Current academic level:",
    admission_registration: "Admission and registration:",
    admission_consultancy: "Admission consultancy:",
    looking_for_scholarship: "Looking for a scholarship:",
    i_have_a_scholarship: "I have a scholarship from my country:",
    desired_country: "Desired country of study:",

    desired_degree:"Desired degree:",
    desired_discipline:"Desired discipline:",
    country_of_residence:"Country of residence:",
    write_discipline:"Write the discipline name",

    address: "Address",
    financial_ability: "Financial ability:",
    select: "Select",

    i_agree_to: "I agree to the ",
    terms_and_conditions: "terms and conditions",
    submit: "Submit",
    field_incomplete: "Error: field(s) incomplete",
    failed_to_submit: "Failed to submit application, try again later",
    field_required: "* this field is required",

    find_dream: "Find your dream study",
    discover: "Discover from of a wide variety of programmes worldwide",
    what_to_study: "Which discipline?",
    where: "Where?",
    search: "Search",
    browse_by_discipline: "Browse by discipline",
    choose_academic_level: "Choose the academic level",
    bachelors_programmes: "Bachelor's programmes",
    masters_programmes: "Master's programmes",
    phd_programmes: "PhD & Doctorate studies",
    what_our_students_say: "What our students say about us",
    read_more: "Read More",
    suggested_programmes: "Suggested Programmes",
    see_all_programmes: "See All Programmes",

    duration: "Duration",
    tuition: "Tuition",
    apply_date: "Apply date",
    start_date: "Start date",

    apply_now: "Apply Now",
    description: "Description",
    other_suggested_programmes: "Other Suggested Programmes",

    no_programmes_found: "No programmes found",
    overview: "Overview",
    browse_programmes_in: "Browse programmes in ",
    by_discipline: "by discipline",
    ranking: "Ranking",
    programmes: "Programmes",
    students: "Students",

    home: "Home",
    study: "Study",
    about_as: "About us",
    contact: "Contact",
    search_programmes: "Search programmes",

    start: "Start",
    per_year: "Per year",
    general_description: "General Description",
    year: "year",
    
    discipline: "Discipline",
    location: "Location",
    tuition_fee: "Tuition fee",
    fee: "Fee",
    duration: "Duration",
    format: "Format",
    attendance: "Attendance",
    degree_type: "Degree type",
    application_form:"Application form",
    phone: "Phone number",

    found: "Found",
    results: "results",

    motto: "Take your academic goals to the next level.",
    terms_of_use: "Terms of use",
    privacy_policy: "Privacy policy",
    follow_us: "Follow Us:",
    email_us: "Email Us:",
    copyright: "Copyright:",
    clear_filters: "Clear filters",
    "UK":"UK",
    "Australia":"Australia",
    "Canada":"Canada",
    "USA":"USA",
    "Malaysia":"Malaysia",
    "turkey":"Turkey",
    "Hungary":"Hungary",

    "Full Time":"Full Time", 
    "Part Time":"Part Time",
    "On Campus":"On Campus", 
    "Online":"Online",

      years: "years",
      year2: "year",
      months: "months",


      "High school education": "High school education",
      "Diploma": "Diploma",
      "Bachelor's": "Bachelor's",
      "Master's": "Master's",
      "Phd": "Phd",

      go_back_home: "Go back home",
      submitted_details: "We will contact you through email and let you know about the next steps, feel free to browse our other programmes in the meantime.",

      student1_story:"Without Ateamspot, I wouldn’t be here today. I finished my bachelor degree in Nursing and started my master’s program. Ateamspot is the bridge that conducted me to achieve my academic plan. It was my best decision of my life. Now I am working and studying abroad. I got to make new friends for all around the world and discover other cultures while enjoying my program study abroad.",
      student2_story:"Studying abroad has been an incredible experience, and especially when it was arranged with a professional team like Ateamspot. They provided me with a list of universities that suits my budget and helped me choosing my major and university, and to be honest it was the most right decision I have ever take. I suggest every student to stop searching on universities website and to go immediately to Ateamspot.com. It will save money, time and wrong choices! ",
      student3_story:"I have applied for an application on Ateamspot.com, then their academic consultants contacted me and offered me free consultation which helped me  to pick my course program. So I know how valuable it can be in assisting students in making the correct decision.\n\nEven though I am already registered in a university in Malayisa, Ateamspot helps me to reserve my tickets in summer and I am always referring it to others students who are suffering through the same struggle that I did when it came to choosing a study.",
      student4_story:"Since grade 12, I was searching for universities abroad for roughly over a year. All the information were massive, messy and unorganized and I could make a final decision until I was introduced to ateamspot. They did all the registration process for me without going a single time to the university for admission. So even if moving from home may raise certain concerns but it is worthwhile to start a new chapter and experience the world",
  };
  