
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Image, Button, Container } from 'react-bootstrap';

import useTranslation from "../../hooks/LocalizationHook";
import { Link } from 'react-router-dom';

import { Routes } from "../../routes";


export default () => {
  const strings = useTranslation();
  
  function memberCard(name, role, imgUrl){
    return (
      <div
        className= "mx-3 my-3 align-items-center justify-content-center"
        style={{
          width:"20em", height:"20em",
          borderRadius:"15px",
          backgroundColor: "#00000000",
        }}
      >
        <Image
        src={imgUrl}
        className="my-4"
        style={{display:"block",  margin:"auto", width:"180px", height:"180px", objectFit: "cover", borderRadius:"50%"}}
        />

        <p 
          className="text-dark"
          style={{padding:"0", textAlign:"center"}}
          >
            <span className="fw-bolder" style={{fontSize: 20}}>{name}</span>
            <br/>
            {role}
        </p>

      </div>
    )
  }

  return (
    <div>
      
      <div className="align-items-center justify-content-center mt-7">
        <p 
            className="text-dark lead my-4 mx-8" 
            style={{ padding:"0em", textAlign:"center", display:"block"}}>
            <span className="fw-bolder" style={{fontSize: 30}}>{strings.our_team}</span> 
        </p>
      </div>

      <Row className="align-items-center justify-content-center mx-4">
        {memberCard("Mohammed Ali",strings.manager,"https://cdn-icons-png.flaticon.com/512/149/149071.png")}
        {memberCard("Nagm",strings.manager,"https://cdn-icons-png.flaticon.com/512/149/149071.png")}
        {memberCard("Yazeed Sabil",strings.developer,"https://cdn-icons-png.flaticon.com/512/149/149071.png")}
        {memberCard("Khalil Hassan",strings.marketing,"https://cdn-icons-png.flaticon.com/512/149/149071.png")}
        {memberCard("Joelle",strings.academic_advisor,"https://cdn-icons-png.flaticon.com/512/149/149071.png")}
        {memberCard("Anfal",strings.personal_relations,"https://cdn-icons-png.flaticon.com/512/149/149071.png")}
      </Row>
      
    </div>
  );
};