
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faArrowDown, faChevronDown, faChevronRight, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Image, Col, Row, Button, } from 'react-bootstrap';

import { Link, useLocation } from 'react-router-dom';

import { Routes } from "../../routes";
import { ReactComponent as clock } from '../../assets/img/icons/wall-clock.svg';
import { ReactComponent as money } from '../../assets/img/icons/money.svg';
import { ReactComponent as calendar } from '../../assets/img/icons/calendar.svg';
import { ReactComponent as natural } from '../../assets/img/disciplines/test.svg';
import { ReactComponent as masters } from '../../assets/img/icons/phd.svg';
import { ReactComponent as place } from '../../assets/img/icons/pin.svg';
import { ReactComponent as bach } from '../../assets/img/icons/bach.svg';



import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import SuggestedProgrammeCard from "../../components/SuggestedProgrammeCard";
import Switch from "react-bootstrap/esm/Switch";
import ProgrammeCardWide from "../../components/ProgrammeCardWide";
import disciplines from "../../data/disciplines";
import { useData } from "../../contexts/DataContext";
import useTranslation from "../../hooks/LocalizationHook";
import { useLocalization } from "../../contexts/LocalizationContext";


export default () => {
  const {language} = useLocalization();
  const strings = useTranslation();
  const locations = ["UK", "Australia", "Canada", "USA", "Malaysia", "turkey", "Hungary"];
  const fees = ["< $5,000", "$5,000 -> $10,000", "$10,000 -> $20,000", "> $20,000"]
  const durations = ["< 1 year", "1 year" ,"1.5 years" ,"2 years", "> 2 years"]
  const formats = ["Full Time", "Part Time"]
  const attendances = ["On Campus", "Online"]
  const degrees =  ["B.Sc", "B.Eng", "M.Sc", "M.Eng", "PHD"]

  const [allFilters, setFilters] = useState([])
  const [filteredProgrammes, setFilteredProgrammes] = useState([])
  const {getAllItemsFromCollection, getItemsInstant} = useData()
  const location = useLocation()

  function filterProgrammes(filters, fetchedItems){
      let progs = fetchedItems?.filter((prog)=> !prog.hidden) ?? programmes?.filter((prog)=> !prog.hidden);

      let disciplinesFiltered = false

      let filteredByDiscipline = []

      disciplines.forEach((disc)=>{
        if(filters.includes(disc.id)){
          disciplinesFiltered = true
          filteredByDiscipline.push(...progs.filter((prog) => prog.discipline === disc.id))
        }
      });

      if(!disciplinesFiltered) 
        filteredByDiscipline = [...progs];


      let locationsFiltered = false

      let filteredByLocation = []
  
      locations.forEach((loc)=>{
        if(filters.includes(loc)){
          locationsFiltered = true
          filteredByLocation.push(...filteredByDiscipline.filter((prog) => prog.country === loc))
        }
      })

      if(!locationsFiltered) 
        filteredByLocation = [...filteredByDiscipline];


      let feesFiltered = false

      let filteredByFees = []
  
      fees.forEach((fee)=>{
        if(filters.includes(fee)){
          feesFiltered = true
          filteredByFees.push(...filteredByLocation.filter((prog) => parsePrice(prog.fee) >= getMin(fee) && parsePrice(prog.fee) <= getMax(fee)))
        }
      })

      if(!feesFiltered) 
        filteredByFees = [...filteredByLocation];

      let durationFiltered = false

      let filteredByDuration = []
  
      durations.forEach((dur)=>{
        if(filters.includes(dur)){
          durationFiltered = true
          filteredByDuration.push(...filteredByFees.filter((prog) => parseDuration(prog.duration) >= getDurationMin(dur) && parseDuration(prog.duration) <= getDurationMax(dur)))
        }
      })

      if(!durationFiltered) 
        filteredByDuration = [...filteredByFees];

      let formatFiltered = false
      let filteredByFormat = []
  
      formats.forEach((loc)=>{
        if(filters.includes(loc)){
          formatFiltered = true
          filteredByFormat.push(...filteredByDuration.filter((prog) => prog.format === loc))
        }
      })

      if(!formatFiltered) 
        filteredByFormat = [...filteredByDuration];

      
      let attendanceFiltered = false
      let filteredByAttendance = []
  
      attendances.forEach((loc)=>{
        if(filters.includes(loc)){
          attendanceFiltered = true
          filteredByAttendance.push(...filteredByFormat.filter((prog) => prog.attendance === loc))
        }
      })

      if(!attendanceFiltered) 
        filteredByAttendance = [...filteredByFormat];

      
      let degreeFiltered = false
      let filteredByDegree = []
  
      degrees.forEach((loc)=>{
        if(filters.includes(loc)){
          degreeFiltered = true
          filteredByDegree.push(...filteredByAttendance.filter((prog) => prog.degree === loc))
        }
      })

      if(!degreeFiltered) 
        filteredByDegree = [...filteredByAttendance];


      let universityFiltered = false
      let filteredByUniversity = []
  
      filters.forEach((uni)=>{
        if(uni.length == 20){
          universityFiltered = true
          filteredByUniversity.push(...filteredByDegree.filter((prog) => prog.univId === uni))
        }
      })

      if(!universityFiltered) 
      filteredByUniversity = [...filteredByDegree];

      setFilteredProgrammes(filteredByUniversity)
  }

  async function toggleFilter(option, value){
    let filters = []

    if(value && !allFilters.includes(option)) 
      filters = [...allFilters, option];
    
    else if(!value && allFilters.includes(option))
      filters = [...allFilters.filter((item)=>item!==option)];

    setFilters(filters)

    filterProgrammes(filters);
  }

  function getMin(fee){
    if(fee.includes("->")){
      let sp = fee.split("->");
      return parsePrice(sp[0])
    }
    else if(fee.includes(">")){
      return parsePrice(fee)+1
    }
    else{
      return 0;
    }
  }

  function getMax(fee){
    if(fee.includes("->")){
      let sp = fee.split("->");
      return parsePrice(sp[1])
    }
    else if(fee.includes("<")){
      return parsePrice(fee)-1
    }
    else{
      return 999999999;
    }
  }

  function parsePrice(price){
    return parseInt(price.replace(">","").replace("<","").replace("$","").replace(" ","").replace(",",""))
  }

  function getDurationMin(dur){
    if(dur.includes(">")){
      return parseDuration(dur)+0.1
    }else if(dur.includes("<")){
      return 0;
    }
    else {
      return parseDuration(dur)
    }
  }

  function getDurationMax(dur){
    if(dur.includes("<")){
      return parseDuration(dur)-0.1
    }else if(dur.includes(">")){
      return 9999999;
    }
    else {
      return parseDuration(dur)
    }
  }

  function parseDuration(dur){
    const clear = dur.replace(">","").replace("<","").replace("year","").replace(" ","").replace("years","");
    const denominator = clear.includes("months")? 12 : 1
    return parseFloat( clear.replace("months","")) / denominator;
  }
  const [programmes, setProgrammes] = useState([])
  
  useEffect(() => {
    const filters = setInitValues();
    console.log(filters)
    initialFetch(filters)
    window.scrollTo(0, 0)
  }, [])
  
  function setInitValues(){
    const filters = [];

    if(location.state?.country)
    {
      let cont = location.state?.country
      const match = locations.filter((l)=>l.toLowerCase().includes(cont.toLowerCase()))
      if(match.length != 0)
        filters.push(match[0])
    }

    if(location.state?.discipline)
    {
      let cont = location.state?.discipline
      const match = disciplines.filter((l)=>l.name.toLowerCase().includes(cont.toLowerCase()))
      if(match.length != 0)
        filters.push(match[0].id)
    }

    if(location.state?.university)
    {
      filters.push(location.state?.university)
    }

    setFilters(filters)

    return filters;
  }

  async function initialFetch(filters){
    let fetchedItems = getAllItemsFromCollection("programmes");
    if(fetchedItems.length !== 0){
      setProgrammes(fetchedItems)
      filterProgrammes(filters, fetchedItems)
    }
    else{
      fetchedItems = await getItemsInstant("programmes");
      setProgrammes(fetchedItems)
      filterProgrammes(filters, fetchedItems)
    }
  }

  function FilterCategory(title, Icon, options,trans, ids, ){
    const [expanded, setExpanded] = useState(false)

    return(
      <div
        // style={{display: "inline-table"}}
      >
      <div
        className="d-flex mt-1 mb-2"
        onClick={()=>setExpanded(!expanded)}
        style={{height: 50, width: "100%", padding: "0px 10px", textAlign:language=="en"?"left":"right", borderStyle:"dotted2", borderBottomStyle: "solid", borderWidth:1, borderColor:"rgba(0,0,0,0.15)", position:"relative", cursor:"pointer", borderSpacing:10}}
      >
      <Icon
        style={{marginBlock: "auto",marginInline: 8,width:"25px", height:"20px", fill:"rgb(24,121,156)", "object-fit": "fill"}}
        />
      <div
        className="fw-bolder d-flex align-items-center justify-content-center"
        style={{textAlign: language=="en"?"left":"right", margin:"auto 0", height:"100%", fontSize: 18,}}
      >
        {title}
        <FontAwesomeIcon 
          icon={expanded? faChevronUp : faChevronDown} 
          className="fw-bolder"
          color="rgba(0,0,0,0.5)"
          style={{width:15, height: 15, position:"absolute", right:language=="en"?"5%":undefined, left:language!="en"?"5%":undefined, }}

        />
      </div>
      </div>
      {
        !expanded? <div/> 
        :
        <div
          style={{borderBottomStyle: "solid", borderWidth:1, borderColor:"rgba(0,0,0,0.1)",}}
        >
          {options.map((option,index)=>
          <div
          className="d-flex"

          style={{borderStyle:"solid2", width:"100%", padding: "5px 15px",textAlign:language=="en"?"left":"right", position:"relative"}}
          >
          <Switch 
            // checked={false} defaultChecked={false} onChange={()=>{}}
            checked={allFilters.includes(ids ? ids[index] : option)}
            onChange={(e)=>toggleFilter(ids ? ids[index] : option, e.target.checked)}
            style={{  marginBlock: "auto",marginInline: 0,width:"25px", height:"20px", fill:"rgb(24,121,156)", "object-fit": "fill"}}
          />
          <div
            className="d-flex align-items-center justify-content-center"
            style={{textAlign: language=="en"?"left":"right", margin:"auto 0", height:"100%", fontSize: 15,}}
          >
            {trans? strings[option] : option.replace("years", strings.years).replace("year", strings.year2).replace("months", strings.months)}
            <p 
            className="d-flex align-items-center justify-content-center"
              style={{ borderStyle:"solid2",textAlign: "right", margin:"auto 0", height:"100%",position:"absolute", right:"5%", fontSize: 12, color:"rgba(0,0,0,0.5)"}}
            >
              
            </p>
          </div>
          </div>
          )}
        </div>
      }
      </div>
    )
  }

  return (
    <div>
      <div
        className= "mt-7 mx-6"
        style={{textAlign:"right"}}
      >
        {strings.found} {filteredProgrammes.length} {strings.results}
      </div>
      <Row 
        className= "d-flex mx-3 mt-3 mb-5 justify-content-center"
      >
      <Col
        className="shadow mb-3"
        sm={12}
        md={4}
        lg={3}
        style={{
        backgroundColor: "#ffffff",
        // maxHeight: 250,
        // width: "25%",
        // left: "7.5%",
        borderRadius:"10px",
        padding: "5px 5px",
        textAlign:language=="en"?"left":"right",
        // margin:"0 20px",
        overflow: "scroll",
        height: "60vh"
      }}
      >
        {allFilters.length === 0 ? <></>: 
        <Button 
        className= "my-1 mx-1" 
        variant="link"
        onClick={()=>{setFilters([]);filterProgrammes([])}}
        // style={{textAlign:"center"}}
      >
        {strings.clear_filters} ({allFilters.length})
        </Button>}
        {FilterCategory(strings.discipline,natural, disciplines.map((disc)=>language=="en"?disc.name:disc.arabicName), false, disciplines.map((disc)=>disc.id))}
        {FilterCategory(strings.location,place, locations, true)}
        {FilterCategory(strings.tuition_fee,money, fees, false)}
        {FilterCategory(strings.duration,clock, durations, false)}
        {FilterCategory(strings.format,calendar, formats, true)}
        {FilterCategory(strings.attendance,masters, attendances, true)}
        {FilterCategory(strings.degree_type,bach, degrees, false)}
      </Col>
      <Col
        className="align-items-center justify-content-center"
        sm={12}
        md={8}
        lg={9}
        style={{
        // width: "65%",
        // left: "7.5%",
        textAlign:language=="en"?"left":"right",
      }}
      >
        
        {
          filteredProgrammes.length === 0? 
          <div style={{textAlign: "center",height: "60vh", left: "50%", top: "50%", position:"absolute"}}>
            No programmes found
          </div>
          : 
          filteredProgrammes.map((p)=>ProgrammeCardWide(p))}
      </Col>
      </Row>
    </div>
  );
};