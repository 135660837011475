import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { HashRouter } from "react-router-dom";
import HomePage from "./pages/HomePage";
// import 'bootstrap/dist/css/bootstrap.min.css';

// import ScrollToTop from "./components/ScrollToTop";
import "./scss/ateamspot.scss";
// import * as serviceWorker from './serviceWorker';
import LocalizedStrings from 'react-localization';




ReactDOM.render(
  <HashRouter>
    {/* <ScrollToTop /> */}
    <HomePage />
  </HashRouter>,
  document.getElementById("root")
);

//Or you can do it where ever you want, base on user selected language, or something else 
// serviceWorker.unregister();
// function distance(lat1, lon1, lat2, lon2) {
//   var p = 0.017453292519943295;    // Math.PI / 180
//   var c = Math.cos;
//   var a = 0.5 - c((lat2 - lat1) * p)/2 + 
//           c(lat1 * p) * c(lat2 * p) * 
//           (1 - c((lon2 - lon1) * p))/2;

//   return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
// }