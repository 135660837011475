
import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Image, Button, Container, Form } from 'react-bootstrap';
import emailjs from 'emailjs-com';

import { Link } from 'react-router-dom';

import { Routes } from "../../routes";
import NotFoundImage from "../../assets/img/illustrations/404.svg";
import '../../assets/css/contact.css'
import LocalizedStrings from "react-localization";
import useTranslation from "../../hooks/LocalizationHook";


export default () => {

  const strings = useTranslation();

  const [email, setEmail] = useState()
  const [name, setName] = useState()
  const [subject, setSubject] = useState()
  const [message, setMessage] = useState()


  function sendEmail(e) {
    e.preventDefault(); 

    const templateParams = {
        from_name: name,
        from_email: email,
        to_name: 'ATeamSpot',
        from_subject: subject,
        message_html: message,
      };

      emailjs.send(
        process.env.REACT_APP_SERVICE_ID_EMAILJS,
        process.env.REACT_APP_TEMPLATE_ID_EMAILJS,
        templateParams,
        process.env.REACT_APP_USER_ID_EMAILJS)
        .then((result) => {
        resetForm();
        console.log("success")
        alert('Your message has been sent successfully. We will contact you soon.');
      }, (error) => {
          console.log("fail",error)
        alert('Failed to send your message, please try again later');
          console.log(error.text);
      });
    };

    function resetForm(){
        setEmail();
        setName();
        setSubject();
        setMessage();
    }

  return ( 
    <div className="pages mt-8"> 
        {/* <div className="section-title">
            <div className="main-title">
                <div className="title-main-page">  
                    <h4>Contact Us</h4>  
                </div>
            </div>
        </div> */}
        <div className="align-items-center justify-content-center mt-7">
        <p 
            className="text-dark lead my-4 mx-3" 
            style={{ padding:"0em", textAlign:"center", display:"block"}}>
            <span className="fw-bolder" style={{fontSize: 30}}>{strings.contact_us}</span> 
        </p>
      </div>

        <Container fluid>    
          
        <Row className="justify-content-md-center py-5">
        <Col xs={12} md={8} lg={8}>    
        <Form   onSubmit={sendEmail}>
            <Form.Row>
                <Form.Group controlId="formGridName" className="contact-input col-sm-12 col-md-6">  
                    <Form.Control
                        type="text"
                        placeholder={strings.name}
                        name="name"
                        value={name}
                        onChange={(e)=>setName(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formGridEmail" className="contact-input col-sm-12 col-md-6"> 
                    <Form.Control
                        type="email"
                        placeholder="email@gmail.com"
                        name="email" 
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                        required
                    />
                </Form.Group>
            </Form.Row> 

            <Form.Group controlId="formGridSubject" className="contact-input col-sm-12 col-md-6"> 
                <Form.Control
                    type="text"
                    placeholder={strings.subject}
                    name="subject"
                    value={subject}
                    onChange={(e)=>setSubject(e.target.value)}
                    required
                    />
            </Form.Group>

            <Form.Group controlId="exampleForm.message" className="contact-input col-sm-12 col-md-6"> 
                <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder={strings.message}
                    name="message"
                    value={message}
                    onChange={(e)=>setMessage(e.target.value)}
                    required
                    />
            </Form.Group> 

            <Button type="submit" value="send" className="mt-2">
                {strings.send_message}
            </Button>
                        
            </Form>
            </Col> 
            </Row>
            </Container> 
    </div>
);
};
