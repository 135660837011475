
import React from "react";
import LocalizedStrings from 'react-localization';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Image, Button, Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import { Routes } from "../routes";

import { ReactComponent as idea } from '../assets/img/icons/idea.svg';
import { ReactComponent as certificate } from '../assets/img/icons/certificate.svg';
import { ReactComponent as tasks } from '../assets/img/icons/tasks.svg';
import { ReactComponent as twentyfour } from '../assets/img/icons/twentyfour.svg';
import { useLocalization } from "../contexts/LocalizationContext";
import useTranslation from "../hooks/LocalizationHook";


export default () => {
  const strings = useTranslation();

  function advantageCard(title, text, Icon){
    return (
      <div
        className= "shadow bg-black mx-3 my-3 align-items-center justify-content-center"
        style={{
          width:"20em", height:"20em",
          borderRadius:"15px",
          backgroundColor: "#00000088",
        }}
      >
        <Icon
        className="my-4"
        style={{display:"block",  margin:"auto", width:"5em", height:"5em", fill:"cyan", "object-fit": "fill"}}
        />

        <p 
          className="text-light"
          style={{padding:"0", textAlign:"center"}}
          >
            <span className="fw-bolder" style={{fontSize: 20}}>{title}</span>
            <br/>
            <br/> 
            {text}
        </p>

      </div>
    )
  }

  return (
    <div>
        <div
        className="d-flex align-items-center justify-content-center"
          style={{
            position:"relative",
            height:"60vh",
            width:"100%",
            paddingTop:"15vh"
          }}
        >
          <Image 
            src = "https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80"
            // src="https://images.unsplash.com/photo-1513258496099-48168024aec0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8c3R1ZGVudCUyMHdpdGglMjBsYXB0b3B8ZW58MHx8MHx8&w=1000&q=80" 
            className= "shadow bg-white rounded"
            style={{
              width:"100%",
              height:"60vh",
              objectFit: "cover",
              position: "absolute"
            }}
          />
          <div
          className= "rounded"
          style={{
            position: "absolute",
            width:"100%",
            height:"60vh",
            backgroundColor: "#00000088",
            // width:"100%",
            // minHeight:"60vh",
            // backgroundColor: "#00000088",
            // position: "absolute"
          }}
        />
        <div
            className="text-light" 
            style={{
              position: "absolute",
              textAlign:"center",
              paddingInline: "10%",
            }}>
            <span className="fw-bolder" style={{fontSize: 30}}>{strings.admission}</span> 
            <br/>
            <br/>
            <span>{strings.pleased}</span>
            <br/>
            <br/>
            <Button as={Link} variant="secondary" className="animate-hover" to={Routes.ChooseLevel.path} style={{textDecoration:"none"}}>
                <FontAwesomeIcon icon={faChevronRight} className="animate-right-3 me-3 ms-2" />
                {strings.see_programmes}
            </Button>
        </div>
        </div>
        
         
       <div className="align-items-center justify-content-center mt-7">
        <p 
            className="text-dark lead my-4" 
            style={{ paddingInline:"10%", textAlign:"center"}}>
            <span className="fw-bolder" style={{fontSize: 30}}>{strings.our_story}</span> 
            <br/>
            <br/>
            {strings.story_details}
            <br/>
            <br/>
            <a href={"#"+Routes.OurStory.path} className="fw-bolder" style={{"text-decoration": "none", color:"rgba(160,180,255,1)"}}> {strings.read_more}</a>
        </p>
        </div>

      <div className="d-flex align-items-center justify-content-center mt-7">
        <div
          className= "shadow bg-white rounded"
          style={{
            width:"100%",
            marginInline:"1%",
            minHeight:"30em",
            backgroundColor: "#00000088",
          }}
        >
          <p 
          className="text-dark lead my-5"
          style={{padding:"0", textAlign:"center"}}
          >
            <span className="fw-bolder" style={{fontSize: 30}}>{strings.advantages}</span> 
          </p>
          <Row className="d-flex align-items-center justify-content-center mt-5 mb-5">
            {advantageCard(strings.adv1_title,strings.adv1_detail,tasks)}
            {advantageCard(strings.adv2_title,strings.adv2_detail,certificate)}
            {advantageCard(strings.adv3_title,strings.adv3_detail,idea)}
            {advantageCard(strings.adv4_title,strings.adv4_detail,twentyfour)}
          </Row>
        </div>
      </div>
      <div className="mt-7">
        <p 
            className="text-dark lead my-4" 
            style={{ padding:"0em", textAlign:"center"}}>
            <span className="fw-bolder" style={{fontSize: 30}}>{strings.our_services}</span>
        </p>
        <Row className="d-flex align-items-center justify-content-center mt-5 mb-5">
          <Col sm={8} md={7} lg={5}  >
          <p 
              className="text-dark lead my-4" 
              style={{ textAlign:"center",}}>
              <span className="fw-bolder" style={{fontSize: 30}}>{strings.service1_title}</span>
              <br/>
              <br/>
              {strings.service1_detail}
          </p>
          </Col>
          <Col sm={4} md={5} lg={5}  >
          <Image 
            src="https://static.wixstatic.com/media/be2c632522231bd3c200e58f5dc4b4a4.jpg/v1/crop/x_0,y_200,w_1024,h_283/fill/w_1213,h_340,al_c,lg_1,q_85,usm_2.00_1.00_0.00/be2c632522231bd3c200e58f5dc4b4a4.webp"
            style={{height:"40vh", "object-fit": "cover", padding:0,margin:0, borderRadius:"15px"}}
            className= "shadow"
          />
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-center mt-5 mb-5">
          <Col sm={4} md={5} lg={5}  >
          <Image 
            src="https://actionhousing.org/wp-content/uploads/2020/06/Photo-3-1800x1000.jpg"
            style={{height:"40vh", "object-fit": "cover", padding:0,margin:0, borderRadius:"15px"}}
            className= "shadow "
          />
          </Col>
          <Col sm={8} md={7} lg={5}  >
          <p 
              className="text-dark lead my-4" 
              style={{  textAlign:"center",}}>
              <span className="fw-bolder" style={{fontSize: 30}}>{strings.service2_title}</span>
              <br/>
              <br/>
              {strings.service2_detail}
              </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};
//  borderColor:"green", borderStyle:"solid",borderWidth:"5"