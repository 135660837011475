
import React, { useEffect, useState } from "react";
import {  Button, Row } from 'react-bootstrap';

import disciplines from "../../data/disciplines";
import { useData } from "../../contexts/DataContext";
// import programmes from "../../data/programmes";
import SuggestedProgrammeCard from "../../components/SuggestedProgrammeCard";
import { Link } from "react-router-dom";
import { Routes } from "../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from 'react-router-dom'
import useTranslation from "../../hooks/LocalizationHook";
import { useLocalization } from "../../contexts/LocalizationContext";
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";



export default () => {
  const strings = useTranslation();
  const {language} = useLocalization();
  const {getAllItemsFromCollection} = useData()

  const [discipline, setDiscipline] = useState(disciplines[2])
  const [selectedDiscipline, setSelectedDiscipline] = useState(disciplines[0].id)
  const [selectedLevel, setSelectedLevel] = useState("masters")
  const [programmes, setProgrammes] = useState([])
  const location = useLocation();

  
  useEffect(() => {
    const fetchedItems = getAllItemsFromCollection("programmes");
    setProgrammes(fetchedItems)
    setSelectedDiscipline(location.pathname.split("/")[location.pathname.split("/").length-1]);
    setSelectedLevel(location.pathname.split("/")[location.pathname.split("/").length-2]);

    disciplines.forEach((dis)=>{if(dis.id === selectedDiscipline) setDiscipline(dis)});
    window.scrollTo(0, 0)
  })

  return (
    <div>
      <div className="d-flex align-items-center justify-content-center mt-6 mx-2">
        <div
          // className= "shadow bg-white rounded"
          style={{
            width:"100%",
            // backgroundColor: "#00000000",
          }}
        >
          <discipline.icon
          className="mt-5 mb-4"
          style={{display:"block",  margin:"auto", width:"5em", height:"5em", fill:"dark", "object-fit": "fill"}}
          />
          <p 
          className="text-dark lead my-5 mx-8"
          style={{padding:"0", textAlign:"center"}}
          >
            <span className="fw-bolder" style={{fontSize: 30}}>{language == "en" ? discipline.name : discipline.arabicName}</span> 
          </p>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center mt-2">
        <div
          className= "shadow bg-white"
          style={{
            width:"100%",
            backgroundColor: "#00000088",
            borderRadius:"30px",
            marginInline:"10%"
          }}
        >
          <p 
          className="text-dark lead my-5"
          style={{padding:"0", textAlign:"center", marginInline: "10%",whiteSpace: "pre-line"}}
          >
            <span className="fw-bolder" style={{fontSize: 25}}>{strings.general_description}</span> 
            <br/>
            <br/>
            <span style={{fontSize: 17}}>{language == "en" ? discipline.description : discipline.arabicDescription}</span> 
            <br/>
            <br/>
            <Button as={Link} to={Routes.ApplicationForm.path} variant="success" className="animate-hover mx-auto" style={{textDecoration:"none", textAlign:"center", }}>
              <FontAwesomeIcon icon={faTelegramPlane} className="animate-right-3 me-3 ms-2" />
              {strings.apply_now}
            </Button>
          </p>
          
        </div>
      </div>
      <div className="align-items-center justify-content-center mt-6 mx-2 lead">
        <div
          // className= "shadow bg-white rounded"
          style={{
            width:"100%",
            // backgroundColor: "#00000000",
          }}
        >
          <p 
          className="text-dark lead my-3 mx-8"
          style={{padding:"0", textAlign:"center"}}
          >
            <span className="fw-bolder" style={{fontSize: 25}}>{strings.suggested_programmes}</span> 
          </p>
          <Row className="d-flex align-items-center justify-content-center mt-5 mb-5">
            {programmes?.filter((prog)=> !prog.hidden).filter((prog)=> prog.level === selectedLevel && prog.discipline === selectedDiscipline).length ===0 ? <p 
          className="text-dark lead my-5 mx-8"
          style={{padding:"0", textAlign:"center"}}
          >
            <span style={{fontSize: 20}}>{strings.no_programmes_found}</span> 
          </p>: programmes?.filter((prog)=> !prog.hidden).filter((prog)=> prog.level === selectedLevel && prog.discipline === selectedDiscipline).map((prog)=>SuggestedProgrammeCard(prog, selectedLevel, selectedDiscipline))}
          </Row>
        </div>
      </div>
      <div className="align-items-center justify-content-center mt-3 mb-5"
          style={{textAlign:"center"}}
          >
      <Button as={Link} variant="secondary" className="animate-hover" to={Routes.Search.path} style={{textDecoration:"none"}}>
                <FontAwesomeIcon icon={faChevronRight} className="animate-right-3 me-3 ms-2" />
                {strings.see_all_programmes}
      </Button>
      </div>
      
    </div>
  );
};
//  borderColor:"green", borderStyle:"solid",borderWidth:"5"