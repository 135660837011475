
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Image, Button, Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import { Routes } from "../../routes";
import useTranslation from "../../hooks/LocalizationHook";
import { useLocalization } from "../../contexts/LocalizationContext";


export default () => {
  const strings = useTranslation();
  const {language} = useLocalization();

  return (
    <div>
      
      <div className="align-items-center justify-content-center mt-7">
        <p 
            className="text-dark lead my-4" 
            style={{ padding:"0em", textAlign:language=="en"?"left":"right", display:"block", marginInline: "10%"}}>
            <span className="fw-bolder" style={{fontSize: 25}}>{strings.our_story}</span> 
            <br/>
            <br/>
            <span className="fw-bolder" style={{fontSize: 30}}>{strings.story_head}
            <br/>{strings.our_mission}</span> 
            <br/>
            <br/>
            <span className="fw-bolder" style={{fontSize: 25}}>{strings.how}</span> 
            <br/>
            {strings.how_details}
            <br/>
            <br/>
            <span className="fw-bolder" style={{fontSize: 25}}>{strings.why}</span> 
            <br/>
            {strings.why_details}<br/>
            <br/>
        </p>
      </div>
      
    </div>
  );
};