import React, { useContext, useState, useEffect } from "react";
import LocalizedStrings from "react-localization";

const LocalizationContext = React.createContext();

export function useLocalization() {
  return useContext(LocalizationContext);
}

export function LocalizationProvider({ children }) {
    const [language, setLang] = useState("en");

    // const strings = new LocalizedStrings({
    //     en:{
    //       admission: "Admission and Registration at International Universities",
    //       pleased: "We are pleased to offer students guidance and administration of admission and registration at international universities, in accordance with simple and integrated service standards to suit the desire of our students interested in joining universities and academic institutes in worldwide.",
    //       see_programmes: "See Programs",
    //       our_story: "Our Story",
    //       story_details: "Ambitious team higher education agency panel is the first and the final destination for the students who are seeking enrollment in the universities and institutes in Malaysia through an official agent, Ambitious Team is one of the most recognized facilitators in academic, civil and social obstacles and challenges faced by international students in Malaysia.",
    //       advantages: "Advantages",
    //       adv1_title: "Integrated Services",
    //       adv1_detail: "We provide integrated services for students through our portal, which includes academic, social and civil services.",
    //       adv2_title: "Officially Accredited Agency",
    //       adv2_detail: "We provide integrated services for students through our portal, which includes academic, social and civil services.",
    //       adv3_title: "7 YEARS",
    //       adv3_detail: "We successfully managed to help many students to enroll in accredited universities and institutes in Malaysia.",
    //       adv4_title: "24/7 Support",
    //       adv4_detail: "The ambitious team is available in three major cities in Malaysia and has a high level of experience in solving social and official academic obstacles.",
    //       our_services: "Our Services",
    //       service1_title: "Admission and Registration",
    //       service1_detail: "The admission and registration process are administered by nominating the appropriate majors or matching the preference sent by the applicants via the Admission and Registration form. Our recommendations for universities and institutes are subject to the academic accreditation of the university internationally and locally in the applicants place of residence.",
    //       service2_title: "Accommodation",
    //       service2_detail: "After completion of the admission process and visa procedures for Malaysia by the follow-up activities, coordination with the Ambition Team by the date of arrival of the student or applicant is advised to be picked up from the airport by an agent from the university applied to and a member from the Ambitious Team, in order to complete the necessary procedures to enter Malaysia, and transportation of the student to the place of residence.",
    //     },
    //     ar: {
    //       admission: "التسجيل و القبول في الجامعات عالميا",
    //       pleased: "We are pleased to offer students guidance and administration of admission and registration at international universities, in accordance with simple and integrated service standards to suit the desire of our students interested in joining universities and academic institutes in worldwide.",
    //       see_programmes: "",
    //     }
    //    });

    function setLanguage(lang){
        setLang(lang);
        document.getElementsByTagName('html')[0].setAttribute("dir", lang == "en" ? "ltr":"rtl");
    }
    
    // function getLanguage(){
    //     return strings.getLanguage();
    // }

    // useEffect(()=>{
    //     window.global.strings = strings
    // })
    
    const value = {
        language,
        setLanguage,
    };

    return (
    <LocalizationContext.Provider value={value}>
        {children}
    </LocalizationContext.Provider>
    );
}
