
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Image, Button, Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import { Routes } from "../../routes";
import { useLocalization } from "../../contexts/LocalizationContext";
import useTranslation from "../../hooks/LocalizationHook";

import male_1 from "../../assets/img/students/male-1.jpg";
import male_2 from "../../assets/img/students/male-2.jpg";
import female_1 from "../../assets/img/students/female-1.jpg";


export default () => {
  const {language} = useLocalization();
  const strings = useTranslation();
  
  return (
    <div>
      <p 
        className=" mt-8"              
        style={{textAlign:"center" }}>
        <span className="fw-bolder" style={{fontSize: 30, textAlign:"center"}}>{strings.what_our_students_say}</span> 
      </p>
      <Row className="d-flex align-items-center justify-content-center mt-5 mb-5 mx-6">
          <Col sm={8} md={7} lg={7}  >
          <p 
              className="text-dark lead my-4" 
              style={{textAlign:language=="en"?"left":"right",fontSize:"18px", paddingInline: 50}}>
              {strings.student1_story}
              {/* <span className="fw-bolder">Angela Buchi Gbandi,</span> Nigeria */}
          </p>
          </Col>
          <Col sm={4} md={5} lg={5}>
          <Image 
            src={male_1}//"https://cdn-clkbb.nitrocdn.com/yvdyHjvxnbyDWFOPGqIgfJuhVjswFevC/assets/static/optimized/rev-d42da31/wp-content/uploads/2015/07/gbandi.jpg"
            style={{height:"300px", width:"400px", "object-fit": "cover", padding:0,margin:0, borderRadius:"15px"}}
            className= "shadow"
          />
          </Col>
        </Row>
      <div className="d-flex align-items-center justify-content-center mt-6">
        <Image 
            src="https://images.unsplash.com/photo-1599687351724-dfa3c4ff81b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
            // src="https://cdn-clkbb.nitrocdn.com/yvdyHjvxnbyDWFOPGqIgfJuhVjswFevC/assets/static/optimized/rev-d42da31/wp-content/uploads/2015/06/StudentLookingForCourses1280.jpg" 
            style={{width:"100%", height:"70vh", "object-fit": "cover"}}
            className= "shadow bg-white rounded"
        />
        <div
          className= "rounded"
          style={{
            width:"100%", height:"70vh",
            backgroundColor: "#00000088",
            position: "absolute"
          }}
        />
        <p 
            className="text-light lead mt-5" 
            style={{position: "absolute", padding:"10%", textAlign:"center"}}>
            {strings.student2_story}
            {/* <span className="fw-bolder" style={{fontSize: 15}}>Anonymous user</span>  */}
        </p>
      </div>
      
      <div className="mt-5">
      <Row className="d-flex align-items-center justify-content-center mt-6 mb-5 mx-6">
      <Col sm={4} md={5} lg={5}  >

          <Image 
            src={male_2}//"https://cdn-clkbb.nitrocdn.com/yvdyHjvxnbyDWFOPGqIgfJuhVjswFevC/assets/static/optimized/rev-d42da31/wp-content/uploads/2015/07/vlad_horatiu.jpg"
            style={{ height:"300px", width:"400px", "object-fit": "cover", padding:0,margin:0, borderRadius:"15px"}}
            className= "shadow"
          />
          </Col>
          <Col sm={8} md={7} lg={7}  >

          <p 
              className="text-dark lead my-4" 
              style={{textAlign:language=="en"?"left":"right",fontSize:"18px"}}>
              {strings.student3_story}
              {/* <span className="fw-bolder">Vlad Horatiu Savin,</span> Romania */}
          </p>
          </Col>
          
        </Row>
        <Row className="d-flex align-items-center justify-content-center mt-6 mb-5 mx-6">
        <Col sm={8} md={7} lg={7}  >

          <p 
              className="text-dark lead my-4" 
              style={{textAlign:language=="en"?"left":"right",fontSize:"18px", paddingInline: 50}}>
              {strings.student4_story}
              {/* <span className="fw-bolder">Karan Prakash,</span> Canada */}
          </p>
          </Col>
          <Col sm={4} md={5} lg={5}  >
          <Image 
            src={female_1}//"https://cdn-clkbb.nitrocdn.com/yvdyHjvxnbyDWFOPGqIgfJuhVjswFevC/assets/static/optimized/rev-d42da31/wp-content/uploads/2015/07/karan_prakash.jpg"
            style={{height:"300px", width:"400px", "object-fit": "cover", padding:0,margin:0, borderRadius:"15px"}}
            className= "shadow mb-5"
          />
          </Col>
        </Row>
      </div>
    </div>
  );
};
//  borderColor:"green", borderStyle:"solid",borderWidth:"5"