import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import Footer from '../components/Footer';
import NavigationBar from '../components/NavigationBar';
import { DataProvider } from '../contexts/DataContext';
import { LocalizationProvider } from '../contexts/LocalizationContext';
import { Routes } from "../routes";
import contact from './about/contact';
import our_story from './about/our_story';
import our_students from './about/our_students';
import our_team from './about/our_team';
import partners from './about/our_team';
import privacy_policy from './about/privacy_policy';

import NotFoundPage from "./common/NotFound";
import ServerError from "./common/ServerError";
import Home from './Home';
import application_form from './study/application_form';
import choose_discipline from './study/choose_discipline';
import choose_level from './study/choose_level';
import discipline_details from './study/discipline_details';
import program_details from './study/program_details';
import search from './study/search';
import university_details from './study/university_details';


// import PrivateRoute from './PrivateRoute';
// import { AuthProvider } from '../contexts/AuthContext';
// import { DataProvider } from '../contexts/DataContext';


// const RouteWithLoader = ({ component: Component, ...rest }) => {
//   const [loaded, setLoaded] = useState(true);

//   useEffect(() => {
//     const timer = setTimeout(() => setLoaded(true), 1000);
//     return () => clearTimeout(timer);
//   }, []);

//   return (
//     <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
//   );
// };

const RouteNormal = ({ component: Component, ...rest }) => {

  return (

    <Route {...rest} render={props => (<><NavigationBar/> <Component {...props} /><Footer/></>) } />
  );
};



// const RouteWithSidebar = ({ component: Component, ...rest }) => {

//   return (
//     <Route {...rest} render={props => (
//       <>
//         {/* <Preloader show={loaded ? false : true} /> */}
//         <Sidebar />

//         <main className="content">
//           <Navbar />
//           <Component {...props} />
//           <Footer toggleSettings={null} showSettings={false} />
//         </main>
//       </>
//     )}
//     />
//   );
// };

export default () => (

  // <AuthProvider>
  <DataProvider>
    <LocalizationProvider>
      <Switch>
        <RouteNormal exact path={Routes.Home.path} component={Home} />

        <RouteNormal exact path={Routes.OurStory.path} component={our_story} />
        <RouteNormal exact path={Routes.OurStudents.path} component={our_students} />
        <RouteNormal exact path={Routes.OurTeam.path} component={our_team} />

        <RouteNormal path={Routes.ChooseDiscipline.path} component={choose_discipline} />
        <RouteNormal exact path={Routes.ChooseLevel.path} component={choose_level} />
        <RouteNormal path={Routes.DisciplineDetails.path} component={discipline_details} />
        <RouteNormal path={Routes.ProgramDetails.path} component={program_details} />
        <RouteNormal path={Routes.UniversityDetails.path} component={university_details} />
        <RouteNormal path={Routes.ApplicationForm.path} component={application_form} />
        <RouteNormal path={Routes.Search.path} component={search} />

        <RouteNormal exact path={Routes.Contact.path} component={contact} />
        <RouteNormal exact path={Routes.PrivacyPolicy.path} component={privacy_policy} />

        <RouteNormal exact path={Routes.NotFound.path} component={NotFoundPage} />

        {/* pages */}
        {/* <PrivateRoute exact path={Routes.DashboardOverview.path} component={DashboardOverview} /> */}

        <Redirect to={Routes.NotFound.path} />
      </Switch>
     </LocalizationProvider>
    </DataProvider>

);
