
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Image, Button, Row, Col, } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import { Routes } from "../../routes";
import programmes from "../../data/programmes";
import { ReactComponent as clock } from '../../assets/img/icons/wall-clock.svg';
import { ReactComponent as money } from '../../assets/img/icons/money.svg';
import { ReactComponent as telegram } from '../../assets/img/icons/telegram.svg';
import { ReactComponent as calendar } from '../../assets/img/icons/calendar.svg';
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import SuggestedProgrammeCard from "../../components/SuggestedProgrammeCard";
import { useData } from "../../contexts/DataContext";
import { useLocation, useHistory } from 'react-router-dom'
import disciplines from "../../data/disciplines";

import moment from "moment-timezone";
import 'moment/locale/ar';
import useTranslation from "../../hooks/LocalizationHook";
import { useLocalization } from "../../contexts/LocalizationContext";


export default () => {
  const strings = useTranslation();
  const {language} = useLocalization();
  const defaultApplicationLink = "https://123formbuilder.com/form-4879476/New-Website-Form"
  const { getAllItemsFromCollection, getProgramme} = useData()

  const [programme, setProgramme] = useState({})
  const [selectedDiscipline, setSelectedDiscipline] = useState(disciplines[0].id)
  const [selectedLevel, setSelectedLevel] = useState("masters")

  const [programmes, setProgrammes] = useState([])
  const location = useLocation();

  const [PID, setProgId] = useState("")
  
  const [width, setWidth] = useState(window.innerWidth)
  const history = useHistory()

  const breakWidth = 768

  
  useEffect(() => {
    let fetchedItems = getAllItemsFromCollection("programmes");
    setProgrammes(fetchedItems)

    setSelectedDiscipline(location.pathname.split("/")[location.pathname.split("/").length-2]);
    setSelectedLevel(location.pathname.split("/")[location.pathname.split("/").length-3]);

    const progId = location.pathname.split("/")[location.pathname.split("/").length-1]
    
    fetchProgramme(progId);

    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  async function fetchProgramme(progId){
    if(progId === ""){history.push(Routes.NotFound.path); return;}

    window.scrollTo(0, 0)
    setProgId(progId);
    const prog = await getProgramme(progId, language);

    if(!prog){history.push(Routes.NotFound.path);return;}

    setProgramme(prog.data())
  }
  function detailItem(title, text, Icon){
    return (
      width > breakWidth ?
      <Col
        sm={3} md={3} lg={3}
        className= "d-flex align-items-center justify-content-center"
        style={{
          // width:200, 
          borderStyle:"solid2"
        }}
      >
        {/* <FontAwesomeIcon icon={icon} /> */}
        <Icon
        style={{  marginInline: 20,minWidth:40, width:40, height: 40, fill:"rgb(24,121,156)", objectFit: "cover", borderStyle:"solid2"}}
        />

        <p 
          className="text-dark"
          style={{padding:"0", margin:"auto 0px", textAlign:language=="en"?"left":"right", borderStyle:"solid2"}}
          >
            <span className="fw-bolder" style={{fontSize: 22}}>{title}</span>
            <br/>
            {text}
        </p>
      </Col>
      :
      <div
        className= "d-flex mx-2 my-0 align-items-center justify-content-center"
        style={{
          width:"10em", 
        }}
      > 
      <Icon
        style={{  marginInline: "20px",width:"40px", height:"40px", fill:"rgb(24,121,156)", "object-fit": "fill"}}
      />
      <p 
          className="text-dark"
          style={{padding:"0", margin:"auto 0px", textAlign:language=="en"?"left":"right", borderStyle:"solid3"}}
          >
            {title}
            {/* <span className="fw-bolder" style={{fontSize: 18}}>{title}</span> */}
        </p>
      </div>
    )
  }
  return (
    <div>
      <div 
        className="d-flex align-items-center justify-content-center mt-5"
        style={{position:"relative"}}
      >
        <Image 
            src={programme.img} 
            style={{width:"100%", height:"80vh", "object-fit": "cover"}}
            className= "shadow bg-white rounded"
        />
        <div
                className="shadow d-flex align-items-center justify-content-center"
                style={
                  width > breakWidth ?
                  {
                    backgroundColor: "#ffffff",
                    bottom: "15%",
                    left: language=="en"?"7.5%":undefined,
                    right: language!="en"?"7.5%":undefined,
                    position:"absolute",
                    borderRadius:"15px",
                    padding: "",
                    textAlign:language=="en"?"left":"right"
                  } :
                  {
                    backgroundColor: "#ffffff",
                    bottom: "15%",
                    margin: "0 auto",
                    position:"absolute",
                    borderRadius:"15px",
                    textAlign:language=="en"?"left":"right"
                } 
              }
            >
              <p 
                className="text-dark"
                style={{padding:"20px", paddingTop:30, textAlign:language=="en"?"left":"right", borderStyle:"solid3"}}
                >
                  <span style={{padding: 10,fontSize: 15,borderStyle:"solid", borderWidth:"1px", borderRadius:20,fontSize: 12}}>{programme.degree}</span>
                  <span style={{padding: 10,fontSize: 15,borderStyle:"solid", borderWidth:"1px", borderRadius:20,fontSize: 12, marginInline:10}}>{strings[programme.format]}</span>
                  <span style={{padding: 10,fontSize: 15,borderStyle:"solid", borderWidth:"1px", borderRadius:20,fontSize: 12, marginInline:0}}>{strings[programme.attendance]}</span>
                  <br/>
                  <br/>
                  <span style={{fontSize: 15}}>{language=="en"?programme.univName:programme.univNameArabic ?? programme.univName}</span>
                  <br/>
                  <span className="fw-bolder" style={{fontSize: 22}}>{language=="en"?programme.name:programme.nameArabic ?? programme.name}</span>
                  <br/>
                  <br/>
                  <Button as={Link} to={Routes.ApplicationForm.path} variant="success" className="animate-hover mx-auto" onClick={()=>{if(false) window.open(programme.applicationLink ?? defaultApplicationLink, "_blank")}} style={{textDecoration:"none", textAlign:"center", width:"100%"}}>
                    <FontAwesomeIcon icon={faTelegramPlane} className="animate-right-3 me-3 ms-2" />
                    {strings.apply_now}
                  </Button> 
              </p>
        </div>
        <Row
                className="shadow d-flex align-items-center justify-content-center"
                style={
                  width > breakWidth ?
                  {
                    width:  "85%",
                    height:"120px",
                    backgroundColor: "#ffffff",
                    bottom: "-60px",
                    position:"absolute",
                    borderRadius:"15px",
                    margin:"auto",
                } : 
                {
                  width: "100%",
                  height:"120px",
                  backgroundColor: "#ffffff",
                  bottom: "-60px",
                  position:"absolute",
                  borderRadius:"15px",
                  margin:"auto",
              }
              }
            >
            {detailItem(programme?.duration?.replace("years", strings.years).replace("months", strings.months), strings.duration, clock)}
            {detailItem(programme.fee, strings.tuition, money)}
            {detailItem(moment(programme.applyDate?.toDate()).locale(language).format("MMM YYYY").toString(), strings.apply_date, telegram)}
            {detailItem(moment(programme.startDate?.toDate()).locale(language).format("MMM YYYY").toString(), strings.start_date, calendar)}
        </Row>
      </div>
      <Row className="d-flex align-items-center justify-content-center mt-6 mb-5" style={{borderStyle:"solid2"}}>
        <Col sm={4} md={4} lg={7}
            className="text-dark lead my-4" 
            style={{ paddingInline:"5%", textAlign:language=="en"?"left":"right", display:"block"}}>
            <span className="fw-bolder" style={{fontSize: 25}}>{strings.description}</span> 
            <br/>
            <br/>
            {language=="en"?programme.description:programme.descriptionArabic ?? programme.description}
        </Col>
        <Col sm={4} md={4} lg={1}
            style={{
                minWidth: 1,
                maxWidth: 1,
                backgroundColor: "#00000000",
                height: 120,
                display: "inline-block"
            }}
            >
            </Col>
        <Col sm={4} md={4} lg={4}
            className="d-flex text-dark lead my-4" 
            style={{ padding:"5%", textAlign:language=="en"?"left":"right"}}>
            <img
            className="mt-0 mb-0"
            src={programme.univIcon}
            style={{ marginInline:"20px", width: 100, height:100, objectFit: "cover",}}
            />
            <p
            className={`text-dark`}
            style={{marginInline:"20px", margin:"auto 0", textAlign:language=="en"?"left":"right",}}
            >
            <a className="fw-bolder" style={{fontSize: 20}} onClick={()=>{if(programme.univId) history.push(`${Routes.UniversityDetails.path}/${programme.univId}`)}}>{language=="en"?programme.univName:programme.univNameArabic ?? programme.univName}</a>
            <span style={{fontSize: 15,display:"block", paddingTop:"-5px",}}>{language=="en"?programme.city:programme.cityArabic ?? programme.city}, {language=="en"?programme.country:programme.countryArabic ?? programme.country}</span>
            {/* <br/>
            attendance, language, ielts score, courses, gpa, living cost */}
            </p>
        </Col>
        </Row>
        <div className="align-items-center justify-content-center mt-5 mx-2 lead">
        <div
          // className= "shadow bg-white rounded"
          style={{
            // width:"100%",
            // backgroundColor: "#00000000",
          }}
        >
          <p 
          className="text-dark lead my-3 mx-2"
          style={{padding:"0", textAlign:"center"}}
          >
            <span className="fw-bolder" style={{fontSize: 25}}>{strings.other_suggested_programmes}</span> 
          </p>
          <Row className="d-flex align-items-center justify-content-center mt-5 mb-5">
          {programmes?.filter((prog)=> !prog.hidden).length ===0 ? <p 
          className="text-dark lead my-5 mx-2"
          style={{padding:"0", textAlign:"center"}}
          >
            <span style={{fontSize: 20}}>{strings.no_programmes_found}</span> 
          </p>:programmes?.filter((prog)=> !prog.hidden).filter((prog)=> prog.level === selectedLevel && prog.discipline === selectedDiscipline && prog.id !== PID).slice(0,3).map((prog)=>SuggestedProgrammeCard(prog,selectedLevel, selectedDiscipline, fetchProgramme))}
          </Row>
        </div>
      </div>
      <div className="align-items-center justify-content-center mt-3 mb-5"
          style={{textAlign:"center"}}
      >
      <Button as={Link} variant="secondary" className="animate-hover" to={Routes.Search.path} style={{textDecoration:"none"}}>
                <FontAwesomeIcon icon={faChevronRight} className="animate-right-3 me-3 ms-2" />
                {strings.see_all_programmes}
      </Button>
      </div>
    </div>
  );
};
//  borderColor:"green", borderStyle:"solid",borderWidth:"5"