
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { useHistory } from "react-router";
import { useLocalization } from "../contexts/LocalizationContext";
import LocalizedStrings from "react-localization";
import useTranslation from "../hooks/LocalizationHook";

export default (programme) => {
    const strings = useTranslation();
    const {language} = useLocalization();
    const max_desc = 250
    const history = useHistory();
    function programmeClicked() {
    //   setDiscipline(id);
      history.push(`/programme_details/${programme.level}/${programme.discipline}/${programme.id}`);
    }
    return (
        <div
            onClick={programmeClicked}
            className="shadow d-flex align-items-center justify-content-center mb-3"
        style={{
        backgroundColor: "#ffffff",
        // height: 250,
        width: "100%",
        left: language=="en"?"7.5%":undefined,
        right: language!="en"?"7.5%":undefined,
        borderRadius:"10px",
        textAlign:language=="en"?"left":"right",
        padding:"20px 0px",
        cursor:"pointer"
      }}
      >
        <div
            // className="text-dark lead my-4" 
            style={{ marginInline:"20px", textAlign:language=="en"?"left":"right", borderStyle:"solid2"}}>
            <div className="fw-bolder" style={{fontSize: 25}}>{language=="en"?programme.name:programme.nameArabic ?? programme.name}</div>
            <div style={{fontSize: 12, lineHeight:1, minHeight: 35}} className="mt-2">            
                {
                (language=="en"?programme.description: programme.descriptionArabic ?? programme.description)?.length > max_desc ? (language=="en"?programme.description: programme.descriptionArabic ?? programme.description)?.slice(0,(max_desc-3))+"..." : (language=="en"?programme.description: programme.descriptionArabic ?? programme.description)}
            </div>
            <div className="fw-bolder mt-4" style={{fontSize: 12,}}>            
                {programme.degree +" / "+ strings[programme.attendance] + " / "+ strings[programme.format]}
            </div>
            <div className="d-flex mt-2" style={{textAlign:language=="en"?"left":"right",}}>
                <img
                src={programme.univIcon}
                style={{  width: 50, height:50, objectFit: "cover",}}
                />
                <p
                className={`text-dark`}
                style={{marginInline:"10px", marginBlock:"auto", textAlign:language=="en"?"left":"right",}}
                >
                <div className="fw-bolder" style={{fontSize: 15}}>{language=="en"?programme.univName:programme.univNameArabic ?? programme.univName}</div>
                <div style={{fontSize: 10,display:"block", paddingTop:"-5px",}}>{language=="en"?programme.city: programme.cityArabic ?? programme.city}, {language=="en"?programme.country: programme.countryArabic ?? programme.country}</div>
                </p>
            </div>
        </div>
        <div
            className="text-dark lead my-0" 
            style={{ width: "50%",padding:"0em", textAlign:language=="en"?"right":"left", display:"block", marginInline:20}}>
            <p>
            <span className="fw-bolder" style={{fontSize: 20}}>{programme.fee} / {strings.year}</span>
            <span className="fw-bolder" style={{fontSize: 15,display:"block", paddingTop:"-5px",}}>{programme.duration.replace("years", strings.years).replace("months", strings.months)}</span>
            {/* <br/>
            attendance, language, ielts score, courses, gpa, living cost */}
            </p>
        </div>
        </div>
    );
};
