
import React, { useRef, useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronLeft, faDatabase, faEye, faEyeSlash, faKey, faLock, faShare, faShareAlt, faShieldAlt, faUserAstronaut } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Image, Button, Container, Form, Dropdown, FormCheck, Alert } from 'react-bootstrap';
import emailjs from 'emailjs-com';

import { Link } from 'react-router-dom';
import Select from 'react-select'
import countryList from 'react-select-country-list'

import { Routes } from "../../routes";
import NotFoundImage from "../../assets/img/illustrations/success.svg";
import '../../assets/css/contact.css'
import Switch from "react-bootstrap/esm/Switch";
import { ReactCountryDropdown } from 'react-country-dropdown'
import 'react-country-dropdown/dist/index.css'
import disciplines from "../../data/disciplines";
import { firestore } from "../../firebase";
import LocalizedStrings from "react-localization";
import useTranslation from "../../hooks/LocalizationHook";
import { faKeybase } from "@fortawesome/free-brands-svg-icons";



export default () => {
  const strings = useTranslation();

  const levels = ["High school education", "Diploma", "Bachelor's", "Master's", "Phd"];
  const locations = ["UK", "Australia", "Canada", "USA", "Malaysia", "turkey", "Hungary"];
  const fees = ["< $5,000", "$5,000 -> $10,000", "$10,000 -> $20,000", "> $20,000"]
  const durations = ["< 1 year", "1 year" ,"1.5 years" ,"2 years", "> 2 years"]
  const formats = ["Full Time", "Part Time"]
  const attendances = ["On Campus", "Online"]
  const degrees =  ["B.Sc", "B.Eng", "M.Sc", "M.Eng", "PHD"]

  const options = useMemo(() => countryList().getData(), [])

  async function submitForm(e) {
    e.preventDefault();
    setFormError(false);
    setError("")
    if(!discipline || !degree || !finance || !academicLevel || !country)
    {
        setFormError(true);
        setError(strings.field_incomplete)
        return;
    }

    setLoading(true)
   
    const data = {
        email: email,//emailRef.current.value,
        name: name,//.current.value,
        phone: phone,//.current.value,
        otherDiscipline: other,//?.current.value ?? "",
        address: address,//.current.value,
        whatsapp: whatsapp,//
        nationality: nationality,//
        academicLevel: academicLevel,
        admission: admission,
        consultancy: consultancy,
        lookingForSchoarship: lookingForSchoarship,
        myCountryScholarship: myCountryScholarship,
        discipline: disciplines.filter((e)=>e.id===discipline)[0].name,//
        degree: degree,
        finance: finance,
        country: country,//
        residence: residence,//
        status: "new",
        createdAt: new Date(),
        lastModified: new Date(),
    };

    try {
      const collection = firestore.collection("applications");
      await collection.add(data);
      setLoading(false)
      setFormSubmitted(true)
    } catch (e) {
      setError(strings.failed_to_submit);
      console.log(e);
      setLoading(false);
    }
  };

  function fieldError(message){
    
    return (<div style={{display:"inline", marginInline:15, color:"red"}}>{message ?? strings.field_required}</div>);
  }

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [other, setOther] = useState("");
  const [address, setAddress] = useState("");
//   const nameRef = useRef();
//   const phoneRef = useRef();
//   const otherRef = useRef();
//   const addressRef = useRef();

  const [whatsapp, setWhatsapp] = useState(false)
  const [nationality, setNationality] = useState()
  const [academicLevel, setAcademicLevel] = useState()
  const [admission, setAdmission] = useState(false)
  const [consultancy, setConsultancy] = useState(false)
  const [lookingForSchoarship, setLookingForSchoarship] = useState(false)
  const [myCountryScholarship, setMyCountryScholarship] = useState(false)
  const [discipline, setDiscipline] = useState()
  const [degree, setDegree] = useState()
  const [finance, setFinance] = useState()
  const [country, setCountry] = useState()
  const [residence, setResidence] = useState()

  const [formError, setFormError] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  return (
    <main>
        
        {formSubmitted ? 
        <section className="d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
              <div>
                <Card.Link as={Link} to={Routes.Home.path}>
                  <Image src={NotFoundImage} className="img-fluid w-50 mt-7" />
                </Card.Link>
                <h2 className="text-primary mt-6">
                  {strings.getLanguage() === "en"? <>Application <span className="fw-bolder">Submitted!</span></>
                  :  <>  تم  
                  <span className="fw-bolder"> تقديم الطلب!</span></>}
                </h2>
                <p className="lead my-4">
                {strings.submitted_details}
            </p>
                <Button as={Link} variant="primary" className="animate-hover mb-5" to={Routes.Home.path}>
                  <FontAwesomeIcon icon={faChevronLeft} className="animate-left-3 me-3 ms-2" />
                  {strings.go_back_home}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section> :
      <div className="pages mt-8"> 
        <Container fluid>    
          <div className="align-items-center justify-content-center mt-7">
        <p 
            className="text-dark lead my-4 mx-8" 
            style={{ padding:"0em", textAlign:"center", display:"block"}}>
            <span className="fw-bolder" style={{fontSize: 30}}>{strings.application_form}</span> 
        </p>
      </div>
        {/* <FontAwesomeIcon icon={faUserAstronaut} size={300} fontSize={300} style={{size:300, fontSize:300, width:300, height:300}}/> */}
        <Row className="justify-content-md-center py-5">
        <Col xs={12} md={8} lg={8}>  
        <Form   onSubmit={submitForm}>
            <Form.Row>
                <Form.Group controlId="formGridName" className="contact-input col-sm-12 col-md-6">  
                    <Form.Control
                        type="text"
                        placeholder={strings.name}
                        name="name"
                        value={name} 
                        onChange={(e)=>{setName(e.target.value)}}
                        // value={nameRef.current.value}
                        // ref={nameRef}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formGridEmail" className="contact-input col-sm-12 col-md-6"> 
                    <Form.Control
                        type="email"
                        placeholder={strings.email}
                        name="email"
                        value={email} 
                        onChange={(e)=>{setEmail(e.target.value)}}
                        // value={emailRef.current.value}
                        // ref={emailRef}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formGridPhone" className="contact-input col-sm-12 col-md-6"> 
                    <Form.Control
                        type="text"
                        placeholder={strings.phone}
                        name="phone" 
                        value={phone} 
                        onChange={(e)=>{setPhone(e.target.value)}}
                        // value={emailRef.current.value}
                        // ref={phoneRef}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formGridPhone" className="col-sm-12 col-md-6 fw-bolder"> 
                {strings.whatsapp} <Switch className="mx-1" checked={whatsapp} onChange={(e)=>setWhatsapp(e.target.checked)} style={{display:"inline"}}></Switch>

                </Form.Group>

                <Form.Group controlId="formGridPhone" className="contact-input col-sm-12 col-md-6 mt-3"> 
               
                <div style={{ display:"inline"}} className="fw-bolder">
                {strings.nationality} 
                </div>
               <div style={{height: 10}} />
                <div style={{display:"inline"}}>
                <Select  menuPortalTarget={document.querySelector('body')} options={options} placeholder={strings.select} value={nationality? {label : nationality} : undefined} onChange={(e)=>{setNationality(e.label)}} />
                </div>
               {(!nationality && formError) ? fieldError() : <></>}
                </Form.Group>

                <Form.Group controlId="formGridPhone" className="contact-input col-sm-12 col-md-6 mt-1"> 
               
                <div style={{marginBottom: 10, display:"inline"}} className="fw-bolder">
                {strings.academic_level} 
                </div>
                <Dropdown style={{display:"inline", marginInline:15}}>
                    <Dropdown.Toggle variant="light" id="dropdown-basic" size="sm">
                        {academicLevel ? strings[academicLevel] : strings.select} <FontAwesomeIcon icon={faChevronDown}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {levels.map((item)=><Dropdown.Item key={item} eventKey={item} onClick={(e)=>setAcademicLevel(item)}>{strings[item]}</Dropdown.Item>)}
                    </Dropdown.Menu>
                </Dropdown>
                {(!academicLevel && formError) ? fieldError() : <></>}
                </Form.Group>
                <div className="fw-bolder">
                    {strings.required_services}
                </div>
                <Form.Group controlId="formGridPhone" className="col-sm-12 col-md-6"> 
                <div className="mt-1"></div>{strings.admission_registration}<Switch className="mx-1"  checked={admission} onChange={(e)=>setAdmission(e.target.checked)} style={{display:"inline"}}></Switch>
                <div className="mt-1"></div>{strings.admission_consultancy}<Switch className="mx-1" checked={consultancy} onChange={(e)=>setConsultancy(e.target.checked)} style={{display:"inline"}}></Switch>
                <div className="mt-1"></div>{strings.looking_for_scholarship}<Switch className="mx-1" checked={lookingForSchoarship} onChange={(e)=>setLookingForSchoarship(e.target.checked)} style={{display:"inline"}}></Switch>
                <div className="mt-1"></div>{strings.i_have_a_scholarship}<Switch className="mx-1"  checked={myCountryScholarship} onChange={(e)=>setMyCountryScholarship(e.target.checked)} style={{display:"inline"}}></Switch>

                </Form.Group>

                <div style={{height: 15}} />

                <Form.Group controlId="formGridPhone" className="contact-input col-sm-12 col-md-6 mt-3"> 
               
                <div style={{marginBottom: 10, display:"inline"}} className="fw-bolder">
                {strings.desired_country}
                </div>
                <Dropdown style={{display:"inline", marginInline:15}}>
                    <Dropdown.Toggle variant="light" id="dropdown-basic" size="sm">
                    {country ? strings[country] : strings.select} <FontAwesomeIcon icon={faChevronDown}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {locations.map((item)=><Dropdown.Item key={item} eventKey={item} onClick={(e)=>setCountry(item)}>{strings[item]}</Dropdown.Item>)}
                    </Dropdown.Menu>
                </Dropdown>
                {(!country && formError) ? fieldError() : <></>}
                </Form.Group>


                <Form.Group controlId="formGridPhone" className="contact-input col-sm-12 col-md-6 mt-1"> 
               
                <div style={{marginBottom: 10, display:"inline"}} className="fw-bolder">
                {strings.desired_degree} 
                </div>
                <Dropdown style={{display:"inline", marginInline:15}}>
                    <Dropdown.Toggle variant="light" id="dropdown-basic" size="sm">
                    {degree ?? strings.select} <FontAwesomeIcon icon={faChevronDown}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {degrees.map((item)=><Dropdown.Item key={item} eventKey={item} onClick={(e)=>setDegree(item)}>{item}</Dropdown.Item>)}
                    </Dropdown.Menu>
                </Dropdown>
                {(!degree && formError) ? fieldError() : <></>}
                </Form.Group>

                <Form.Group controlId="formGridPhone" className="contact-input col-sm-12 col-md-6 mt-1"> 
               
                <div style={{display:"inline"}} className="fw-bolder">
                {strings.desired_discipline} 
                </div>
                <div style={{height: 10}} />
                <Select menuPortalTarget={document.querySelector('body')} options={[...disciplines,{"id": "other", "name": "other"},].map((item)=> ({label : strings.getLanguage()==="en"? item.name : item.arabicName, value: item.id}))} placeholder={strings.select} value={discipline? {label : strings.getLanguage()==="en"? disciplines.filter((e)=>e.id===discipline)[0].name : disciplines.filter((e)=>e.id===discipline)[0].arabicName} : undefined} onChange={(e)=>{setDiscipline(e.value)}} />

                {/* <Dropdown style={{display:"inline", marginInline:15, zIndex:9999}}>
                    <Dropdown.Toggle variant="light" id="dropdown-basic" size="sm">
                    {discipline ?? strings.select} <FontAwesomeIcon icon={faChevronDown}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu menuPortalTarget={document.querySelector('body')} menuPosition={'fixed'} style={{zIndex:9999}}>
                    {[...disciplines,{"id": "other", "name": "Other" },].map((item)=><Dropdown.Item key={item.id} eventKey={item.id} onClick={(e)=>setDiscipline(item.name)}>{item.name}</Dropdown.Item>)}

                    </Dropdown.Menu>
                </Dropdown> */}
                {(!discipline && formError) ? fieldError() : <></>}
                </Form.Group>
                {discipline !== "Other" ? <></> :
                <Form.Group controlId="formGridPhone" className="contact-input col-sm-12 col-md-6"> 
                    <Form.Control
                        type="text"
                        placeholder={strings.write_discipline}
                        name="other" 
                        value={other} 
                        onChange={(e)=>{setOther(e.target.value)}}
                        // value={emailRef.current.value}
                        // ref={otherRef}
                    />
                </Form.Group>}

                <Form.Group controlId="formGridPhone" className="contact-input col-sm-12 col-md-6 mt-3"> 
               
               <div style={{marginBottom: 15, display:"inline"}} className="fw-bolder">
               {strings.country_of_residence} 
               </div>
               <div style={{height: 10}} />
               <div style={{display:"inline"}}>
                <Select menuPortalTarget={document.querySelector('body')} options={options} placeholder={strings.select} value={residence? {label : residence} : undefined} onChange={(e)=>{setResidence(e.label)}} />

                     {/* <ReactCountryDropdown onSelect={(c)=>setResidence(c.name)} countryCode='SA' /> */}
               </div>
               {(!residence && formError) ? fieldError() : <></>}
               
               </Form.Group>
               
               <Form.Group controlId="formGridPhone" className="contact-input col-sm-12 col-md-6"> 
                    <Form.Control
                        type="text"
                        placeholder={strings.address}
                        name="other" 
                        value={address} 
                        onChange={(e)=>{setAddress(e.target.value)}}
                        // value={emailRef.current.value}
                        // ref={addressRef}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formGridPhone" className="contact-input col-sm-12 col-md-6 mt-3"> 
               
                <div style={{marginBottom: 10, display:"inline"}} className="fw-bolder">
                {strings.financial_ability} 
                </div>
                <Dropdown style={{display:"inline", marginInline:15}}>
                    <Dropdown.Toggle variant="light" id="dropdown-basic" size="sm">
                    {finance ?? strings.select} <FontAwesomeIcon icon={faChevronDown}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {fees.map((item)=><Dropdown.Item key={item} eventKey={item} onClick={(e)=>setFinance(item)}>{item}</Dropdown.Item>)}
                    </Dropdown.Menu>
                </Dropdown>
                {(!finance && formError) ? fieldError() : <></>}
                </Form.Group>

                <FormCheck type="checkbox" className="d-flex mb-4">
                    <FormCheck.Input required id="terms" className="mx-2" />
                    <FormCheck.Label htmlFor="terms">
                      {strings.i_agree_to}<Card.Link href={"#"+Routes.PrivacyPolicy.path} target="_blank">{strings.terms_and_conditions}</Card.Link>
                    </FormCheck.Label>
                  </FormCheck>

               <div style={{height: 10}} />

                
            </Form.Row>
            {error && <Alert variant="danger">{error}</Alert>}

            <Button type="submit" className="mt-2" disabled={loading}>
                {strings.submit}
            </Button>
                        
            </Form>
            </Col> 
            </Row>
            </Container>
    </div>}
    </main>
);
};
