//import React, { useState, useContext } from "react";
import LocalizedStrings from "react-localization";
import { useLocalization } from "../contexts/LocalizationContext";
import localization from "../assets/localization";

export default function useTranslation() {
  const { language } = useLocalization();
  let translation = new LocalizedStrings(localization);

  translation.setLanguage(language);
  return translation;
}
