
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import useTranslation from "../hooks/LocalizationHook";
import { useLocalization } from "../contexts/LocalizationContext";
import { Routes } from "../routes";

export default (props) => {
  const strings = useTranslation();
  const {language} = useLocalization();
  return (
    <div className="bg-dark rounded shadow">
      <footer className="page-footer font-small pt-4">
    <div className={language=="en"?"container-fluid text-center text-md-left":"container-fluid text-center text-md-right"} style={{color:"white"}}>
        <div className="row">
            <div className="col-md-4 mt-md-0 mt-3">
                <h5 className="text-uppercase" style={{color:"white"}}>ATeamSpot</h5>
                <p style={{color:"rgba(255,255,255,0.7)"}}>{strings.motto}</p>
            </div>

            <hr className="clearfix w-100 d-md-none pb-0"/>

            <div className="col-md-5 mb-md-0 mb-3">
                {/* <h5 className="text-uppercase" style={{color:"white"}}>Content</h5> */}
                <ul className="list-unstyled">
                    <li><a href={"#"+Routes.PrivacyPolicy.path} style={{color:"white"}}>{strings.terms_of_use}</a></li>
                    <li><a href={"#"+Routes.PrivacyPolicy.path} style={{color:"white"}}>{strings.privacy_policy}</a></li>
                    <li><a href={"#"+Routes.Contact.path} style={{color:"white"}}>{strings.contact}</a></li>
                </ul>
            </div>

            <div className="col-md-3 mb-md-0 mb-3" >
                <ul className="list-unstyled">
                    <li>
                        <p className="mx-1"style={{display:"inline"}}>{strings.follow_us}</p>
                        <a href="https://www.facebook.com/ateamspot" style={{color:"white"}} target="_blank"><FontAwesomeIcon icon={faFacebook} className="mx-0"></FontAwesomeIcon></a>
                        <a href="https://www.twitter.com/ateamspot" style={{color:"white"}} target="_blank"><FontAwesomeIcon icon={faTwitter} className="mx-2"></FontAwesomeIcon></a>
                        <a href="https://www.instagram.com/ateamspot" style={{color:"white"}} target="_blank"><FontAwesomeIcon icon={faInstagram} className="mx-0"></FontAwesomeIcon></a>
                    </li>
                    <li>
                        <p className="mx-1"style={{display:"inline"}}>{strings.email_us} <span className="fw-bolder">info@ateamspot.com</span></p>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div className="footer-copyright text-center py-3" style={{color:"white"}}>© {new Date().getFullYear()} {strings.copyright}
        <a href="https://ateamspot.com/" style={{color:"grey"}}> ateamspot.com</a>
    </div>

</footer>
    </div>
  );
};
