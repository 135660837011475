
export const Routes = {
    Home: { path: "/" },
    AboutUs: { path: "/about_us" },
    OurStory: { path: "/our_story" },
    OurStudents: { path: "/our_students" },
    OurTeam: { path: "/our_team" },
    ChooseDiscipline: { path: "/choose_discipline" },
    ChooseLevel: { path: "/choose_level" },
    DisciplineDetails: { path: "/discipline_details" },
    ProgramDetails: { path: "/programme_details" },
    UniversityDetails: { path: "/university_details" },
    ApplicationForm: { path: "/application_form" },
    Search: { path: "/search" },
    Contact: { path: "/contact" },
    PrivacyPolicy: { path: "/privacy_policy" },


    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
};