
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import useTranslation from "../hooks/LocalizationHook";
import { useLocalization } from "../contexts/LocalizationContext";

export default ({id, name, img, description, univName, univIcon, country, city, duration, fee, startDate, degree, nameArabic, descriptionArabic,univNameArabic,countryArabic,cityArabic}, level, discipline, click) => {
    const strings = useTranslation();
    const {language} = useLocalization();
    // const [isHovering, setHovering] = useState(false);
    // const {setprogramme} = useData();
    // const history = useHistory();

    // function handleMouseHover() {
    //   setHovering(!isHovering);
    // }
    // function programmeClicked() {
    //   setprogramme(id);
    //   history.push("/programme_details");
    // }
    const history = useHistory();
    function programmeClicked() {
      history.push(`/programme_details/${level}/${discipline}/${id}`);

      if(click)
        click(id);
    }
    const isHovering = false
    const max_desc = 250

    function divider(){
        return (
            <div 
            style={{
                width: "1px",
                backgroundColor: "#00000088",
                margin: "0 15px",
                height: "50px",
                paddingTop:"-15px"
            }}
            >
            </div>
        );
    }

    return (
      <div
        className= {`shadow mx-4 my-2 align-items-center justify-content-center ${isHovering?"bg-black":"bg-white"}`}
        // onClick={programmeClicked}
        // onMouseEnter={handleMouseHover}
        // onMouseLeave={handleMouseHover}
            onClick={programmeClicked}
            style={{
          width:"20em", 
          minHeight:"25em",
          borderRadius:"15px",
          backgroundColor: "#00000000",
          cursor: "pointer",
          padding:"0"
        }}
      >
          <div 
          className="mt-0 mb-4"
          style={{position:"relative", width:"100%", height:"40%",}}
          >
            <img
                className="mt-0 mb-0"
                src={img?? "https://monet-prtl-co.imgix.net/Shared/StudyPage/CoverImageBasicDesktop.svg"}
                style={{display:"block", padding:"0",  margin:"0",width:"100%", height: 200, objectFit: "cover", borderTopLeftRadius:"15px",borderTopRightRadius:"15px",}}
            />
            <div
                className="shadow"
                style={{
                    // width:"100px",
                    // height:"100px",
                    backgroundColor: "#ffffff",
                    left: "5%",
                    top: "10%",
                    position:"absolute",
                    borderRadius:"5px",
                    // borderColor:"black",
                    // borderWidth:"1px",
                    // borderStyle:"solid"
                }}
            >
                <p 
                    className="text-dark" 
                    style={{  textAlign:"center", padding:"0px 10px",margin:"0"}}>
                        <span className="fw-bolder" style={{fontSize: 10}}>{degree}</span> 
                </p>
            </div>
        </div>
        
        <p 
          className={`text-${isHovering? "light":"dark"}`}
          style={{marginInline:"20px", textAlign:language=="en"?"left":"right"}}
          >
            <span className="fw-bolder" style={{fontSize: 18}}>{language=="en"?name:nameArabic ?? name}</span>
        </p>
        <div className="d-flex mt-0 mb-0">
            <img
            className="mt-0 mb-0"
            src={univIcon}
            style={{ marginInline:"20px",  width:"45px", height:"45px", objectFit: "cover",}}
            />
            <p
            className={`text-${isHovering? "light":"dark"}`}
            style={{ paddingTop:"0px", textAlign:language=="en"?"left":"right"}}
            >
            <span className="fw-bolder" style={{fontSize: 12}}>{language=="en"?univName:univNameArabic ?? univName}</span>
            <span style={{fontSize: 10,display:"block", paddingTop:"-5px",}}>{language=="en"?city:cityArabic ?? city}, {language=="en"?country:countryArabic ?? country}</span>
            </p>
        </div>
        <div>
        <p
            className={`text-${isHovering? "light":"dark"}`}
            style={{paddingInline:"20px", paddingTop:"0px", textAlign:language=="en"?"left":"right",fontSize: 12, height: 80,
            overflow:"hidden",
            // whiteSpace:"nowrap",
            textOverflow:"ellipsis"
        }}
            >
            {language=="en"?description:descriptionArabic ?? description}
            {/* // let desc = language=="en"?description:descriptionArabic;
            // description.length > max_desc ? description.slice(0,(max_desc-3))+"..." : description} */}
            </p>
        </div>
        <div className="d-flex align-items-center justify-content-center">
        <p
                className={`text-${isHovering? "light":"dark"}`}
                style={{paddingInline:"20px", paddingTop:"10px", textAlign:"center",fontSize: 12}}
            >
                <span className="fw-bolder" style={{fontSize: 15}}>{duration?.replace("years", strings.years).replace("months", strings.months)}</span>
                <br/>
                <span style={{fontSize: 10}}>{strings.duration}</span>
            </p>
            {divider()}
            <p
                className={`text-${isHovering? "light":"dark"}`}
                style={{paddingInline:"20px", paddingTop:"10px", textAlign:"center",fontSize: 12}}
            >
                <span className="fw-bolder" style={{fontSize: 15}}>{fee}</span>
                <br/>
                <span style={{fontSize: 10}}>{strings.fee}</span>
            </p>
            {divider()}
            <p
                className={`text-${isHovering? "light":"dark"}`}
                style={{paddingInline:"20px", paddingTop:"10px", textAlign:"center",fontSize: 12}}
            >
                <span className="fw-bolder" style={{fontSize: 15}}>{moment(startDate.toDate()).locale(language).format("MMM YYYY").toString()}</span>
                <br/>
                <span style={{fontSize: 10}}>{strings.start}</span>
            </p>
        </div>
      </div>
    );
};
