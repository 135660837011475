export default {
    
  admission: "التسجيل و القبول في الجامعات عالميا",
  
     home: "الرئيسية",
      study: "الدراسة",
      about_as: "نبذة عنا",
      contact: "تواصل معنا",
      search_programmes: "البحث عن البرامج",
  
  
      admission: "التسجيل و القبول في الجامعات العالمية",
      pleased: "نعمل على تقديم الإرستشارات  للطلاب ومتابعة عملية القبول والتسجيل في الجامعات العالمية ، وفق معايير خدمة بسيطة ومتكاملة بما يتناسب مع رغبة طلابنا المهتمين بالالتحاق بالجامعات والمعاهد الأكاديمية في جميع أنحاء العالم",
  
      see_programmes: "تعرّف على البرامج الأكاديمية",
      our_story: "نبذة عنّا",
      story_details:"يعد فريق الطموح وكالة للتعليم العالي وهو الوجهة الأولى والنهائية للطلاب الذين يسعون للالتحاق بالجامعات والمعاهد في ماليزيا من خلال وكيل رسمي. يعتبر فريق الطموح واحدًا من أفضل الفرق المعترف بهم في تسهيل العقبات والتحديات الأكاديمية والمدنية والاجتماعية التي يواجهها الطلاب الدوليين في ماليزيا.",
      advantages: "أهميّة العمل معنا",
      adv1_title: " خدمات متكاملة",
      adv1_detail: " نقدم خدمات متكاملة للطلاب من خلال بوابتنا التي تشمل الخدمات الأكاديمية والاجتماعية والمدنية",
      adv2_title: " وكالة معتمدة رسميًا",
      adv2_detail: "وفقا لتوجه وزارة التعليم العالي بماليزيا لحصر وكلاء رسميين لإدارة القبول والتسجيل تم إعتماد فريق الطموح  رسميا بماليزيا.",
      adv3_title: "سبع سنوات",
      adv3_detail: " نجحنا في مساعدة العديد من الطلاب على الالتحاق بالجامعات والمعاهد المعتمدة في ماليزيا",
      adv4_title: " دعم على مدار الساعة",
      adv4_detail: " فريقنا موجود في ثلاث مدن رئيسية في ماليزيا ولديه مستوى عالٍ من الخبرة في حل العقبات الأكاديمية الاجتماعية والرسمية",
      our_services: "خدماتنا",
      service1_title: " القبول والتسجيل",
      service1_detail: "إنّ إدارة عملية القبول والتسجيل تتمّ عن طريق عرض التخصصات المناسبة أو مطابقتها مع متطلبات الطلاب عبر طلب القبول والتسجيل الذي يرسله المتقدمون. وتخضع توصياتنا المرسلة للجامعات والمعاهد للاعتماد الأكاديمي الجامعي المطبق عالميا دوليًا ومحليًا في مكان إقامة المتقدمين",
      service2_title: " الإقامة",
      service2_detail: "بعد الانتهاء من عملية القبول وإجراءات التأشيرة لماليزيا، ننصح بالتنسيق مع فريق الطموح فور حلول موعد وصول الطالب أو مقدم الطلب ليتم اصطحابه من المطار من قبل وكيل الجامعة وعضو من فريق الطموح ، وذلك لاستكمال الإجراءات اللازمة لدخول ماليزيا ، ونقل الطالب إلى مكان إقامته.",
  
      contact_us: "تواصل معنا",
      send_message: "أرسل",
      subject: "الموضوع",
      name: "الاسم",
      email: "البريد الالكتروني",
      message: "نصّ الرسالة",
  
      story_head:" فريق الطموح هو المنصة الماليزية لاختيار الدراسة",
      our_mission: " مهمتنا: تشجيع العالم لاختيار التعليم",
      how:"كيف؟",
      how_details:"يعد فريق الطموح وكالة للتعليم العالي وهو الوجهة الأولى والنهائية للطلاب الذين يسعون للالتحاق بالجامعات والمعاهد في ماليزيا من خلال وكيل رسمي. يعتبر فريق الطموح واحدًا من أفضل الفرق المعترف بهم في تسهيل العقبات والتحديات الأكاديمية والمدنية والاجتماعية التي يواجهها الطلاب الدوليين في ماليزيا.",
  
      why:"لماذا؟",
      why_details:" يعتبر فريق الطموح ان تجربة الدراسة الدولية هي ذات قيمة كبيرة لتنمية الطلاب. من خلال بواباتنا ، نحاول سد فجوة المعلومات التي تعيق الطلاب من العثور وبدء دراساتهم الدولية. في هذا السياق ، لا يزال هناك الكثير من العمل الذي يتعين القيام به ، لكننا نعمل بحماس ونرحب بالتعاون مع الطلاب والجامعات كافة",
  
      our_team:"تعرّف على فريقنا",
      manager:"مدير تنفيذي",
      developer:"مطوّر برامج",
      marketing:"تسويق",
      academic_advisor:"مرشد أكاديمي",
      personal_relations:"علاقات عامة",
      student_director:"مدير الطلاب",
  
      whatsapp: "لدي حساب واتساب:",
      nationality:"الجنسية: ",
      academic_level: "المستوى الأكاديمي الحالي:",
      admission_registration: "القبول والتسجيل:",
      admission_consultancy: "استشارات القبول:",
      looking_for_scholarship: "البحث عن منحة دراسية:",
      i_have_a_scholarship: "لدي منحة دراسية من بلدي:",
      desired_country: "بلد الدراسة المطلوب:",
  
      desired_degree:"الشهادةالمطلوبة:",
      desired_discipline: "الاختصاص المطلوب:",
      country_of_residence: "بلد الإقامة:",
      write_discipline: "اكتب اسم التخصص",
  
      address: "العنوان",
      financial_ability: "القدرة المالية:",
      select:"حدّد",
  
      i_agree_to: "أوافق على " ,
      terms_and_conditions: "الشروط والأحكام" ,
      submit: "إرسال",
      field_incomplete: "خطأ: الحقول غير مكتملة" ,
      fail_to_submit: "فشل إرسال الطلب ، حاول مرة أخرى لاحقًا" ,
      field_required: "* هذا الحقل مطلوب" ,
  
      find_dream: "اعثر على دراسة أحلامك" ,
      discover: "اكتشف من بين مجموعة واسعة من البرامج حول العالم" ,
      what_to_study: "أي التخصص؟",
      where:"أين؟" ,
      search: "بحث ",
      browse_by_discipline: "التصفح حسب التخصص" ,
      choose_academic_level: "اختر المستوى الأكاديمي" ,
      bachelors_programmes: "برامج البكالوريوس" ,
      masters_programmes: "برامج الماجستير" ,
      phd_programmes: "دراسات الدكتوراه",
      what_our_students_say: "ماذا يقول طلابنا عنا",
      read_more: "قراءة المزيد",
      suggested_programmes: "البرامج المقترحة" ,
      see_all_programmes: "شاهد كافة البرامج",
  
      duration: "المدة",
      tuition_fee: "الرسوم الدراسية" ,
      application_date: "تاريخ التطبيق" ,
      start_date: "تاريخ البدء",
      phone: "رقم الهاتف",
      apply_date: "تاريخ التقديم",
  
      apply_now: "قدم الآن" ,
      description: "الوصف" ,
      other_suggested_programmes: "برامج أخرى مقترحة",
  
      no_programmes_found: "لم يتم العثور على برامج",
      overview : "نظرة عامة" ,
      browse_programmes_in: " تصفح البرامج في ",
      by_discipline: "عن طريق التخصص",
      ranking: "الترتيب" ,
      programmes: "البرامج" ,
      students: "الطلاب" ,
      tuition: "الرسوم",

  
      start: "يبدأ" ,
      per_year: "في السنة",
      general_description: "وصف عام",
      year: "للسنة",
      
      discipline: "التخصص",
      location: "الموقع",
      fee: "الرسوم",
      format: "تنسيق",
      attendance: "الحضور",
      degree_type: "نوع الدرجة",
      application_form:"استمارة التقديم",
  
      found: "تم ايجاد",
      results: "نتائج",
  
      motto: "خذ أحلامك التعليمية الى مستوى آخر",
      terms_of_use: "شروط الاستخدام",
      privacy_policy: "سياسة الخصوصية",
      follow_us: "إتبعنا على:",
      email_us: "راسلنا على:",
      copyright: "حقوق النشر:",
      clear_filters: "مسح الفلاتر",

      years: "سنة",
      year2: "سنة",
      months: "أشهر",

      "UK":"بريطانيا",
      "Australia":"أستراليا",
      "Canada":"كندا",
      "USA":"أمريكا",
      "Malaysia":"ماليزيا",
      "turkey":"تركيا",
      "Hungary":"هنغاريا",

      "Full Time":"دوام كامل", 
      "Part Time":"دوام جزئي",
      "On Campus":"في الجامعة", 
      "Online":"أونلاين",

      "High school education": "التعليم الثانوي",
      "Diploma": "دبلوم",
      "Bachelor's": "بكلاريوس",
      "Master's": "ماجستير",
      "Phd": "دكتوراة",

      go_back_home: "العودة للرئيسية",
      submitted_details: "سنتصل بك عبر البريد الإلكتروني ونخبرك بالخطوات التالية ، فلا تتردد في تصفح برامجنا الأخرى في الوقت الحالي.",

      student1_story:"كانت الدراسة في الخارج تجربة رائعة ، وخاصة عندما تم الترتيب لها مع فريق محترف مثل Ateamspot. لقد قدموا لي قائمة بالجامعات التي تناسب ميزانيتي وساعدوني في اختيار تخصصي وجامعتي ، ولكي أكون صادقًا ، كان هذا القرار الصائب الذي اتخذته على الإطلاق. أقترح على كل طالب التوقف عن البحث على موقع الجامعات والذهاب فورًا إلى Ateamspot.com.  سيوفر ذلك المال والوقت والخيارات الخاطئة! ",
      student2_story:"بدون Ateamspot ، لما كنت  هنا اليوم. أنهيت درجة البكالوريوس في التمريض وبدأت برنامج الماجستير\n\n. Ateamspot هو الجسر الذي قادني لتحقيق خطتي الأكاديمية. كان أفضل قرار لي في حياتي. الآن أنا أعمل وأدرس في الخارج. تمكنت من تكوين صداقات جديدة من جميع أنحاء العالم واكتشاف الثقافات الأخرى أثناء استمتاعي ببرنامج دراستي في الخارج.",
      student3_story:"لقد تقدمت بطلب على  Ateamspot.com ، للحصول على استشارة اكاديمية ثم اتصل بي مستشارهم الأكاديمي وقدم لي استشارة مجانية ساعدتني في اختيار تخصصي الجامعي. لذا فأن الاستشارة الاكاديمية  مهمة جدا في مساعدة الطلاب في اتخاذ القرار الصحيح.\n\nوعلى الرغم من أنني مسجل بالفعل في إحدى الجامعات في Malayisa ، إلا أن Ateamspot يساعدني في حجز تذاكر السفر الخاصة بي في الصيف وأنا أنصح دائمًا الطلاب الآخرين الذين يعانون من نفس المعاناة التي مررت بها عندما يتعلق الأمر باختيار الدراسة التوجه الى  Ateamspot",
      student4_story:"منذ الصف الثاني عشر ، كنت أبحث عن جامعات في الخارج طيلة فترة عام . كانت جميع المعلومات ضخمة وفوضوية وغير منظمة ولم استطع من اتخاذ قرار نهائي حتى يتم تقديمي إلى ateamspot.\n\nلقد قاموا بكل عملية التسجيل الجامعي و الاستحصال على قبول من أجلي دون الذهاب مرة واحدة إلى الجامعة. لذلك ، حتى لو كان الانتقال من بلدك قد يثير بعض المخاوف ، إلا أنه من المفيد بدء فصل جديد وتجربة جديدة.",
    };
    