
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCertificate, faChevronLeft, faChevronRight, faHome, faHouseUser, faPiggyBank, faSchool } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Image, Button, Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import { Routes } from "../../routes";

import { ReactComponent as bach } from '../../assets/img/icons/bach.svg';
import { ReactComponent as masters } from '../../assets/img/icons/master.svg';
import { ReactComponent as phd } from '../../assets/img/icons/phd.svg';
import { ReactComponent as twentyfour } from '../../assets/img/icons/twentyfour.svg';
import { useData } from "../../contexts/DataContext";

import { useHistory } from "react-router";
import useTranslation from "../../hooks/LocalizationHook";
import { useLocalization } from "../../contexts/LocalizationContext";

import male_1 from "../../assets/img/students/male-1.jpg";

export default () => {
  const strings = useTranslation();
  const {language} = useLocalization();
  const history = useHistory();
  const {setLevel} = useData()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  function levelClicked(id) {
    setLevel(id);
    history.push(`${Routes.ChooseDiscipline.path}/${id}`);
  }

  function levelCard(title, id, Icon){
    return (
      <div
        className= "shadow bg-black mx-4 my-3 align-items-center justify-content-center"
        style={{
          width:"18em", height:"18em",
          borderRadius:"15px",
          backgroundColor: "#00000088",
        }}
      >
        <Icon
        className="mt-5 mb-4"
        style={{display:"block",  margin:"auto", width:"5em", height:"5em", fill:"cyan", "object-fit": "fill"}}
        />
        {/* <FontAwesomeIcon 
          icon={faSchool} 
          color="cyan"
          className="mt-5 mb-4"
          style={{display:"block",  margin:"auto", width:"5em", height:"5em"}}
        /> */}
        <p 
          className="text-light"
          style={{padding:"0", textAlign:"center"}}
          >
            <span className="fw-bolder" style={{fontSize: 20}}>{title}</span>
            <br/>
            <br/> 
            <Button onClick={()=>levelClicked(id)} variant="secondary" className="animate-hover" style={{textDecoration:"none"}}>
                <FontAwesomeIcon icon={faChevronRight} className="animate-right-3 me-3 ms-2" />
                {strings.select}
            </Button>
        </p>

      </div>
    )
  }

  return (
    <div>
      <div className="d-flex align-items-center justify-content-center mt-6 mx-2">
        <div
          // className= "shadow bg-white rounded"
          style={{
            width:"100%", minHeight:"30em",
            // backgroundColor: "#00000000",
          }}
        >
          <p 
          className="text-dark lead my-5 mx-3"
          style={{padding:"0", textAlign:"center"}}
          >
            <span className="fw-bolder" style={{fontSize: 30}}>{strings.choose_academic_level}</span> 
          </p>
          <Row className="d-flex align-items-center justify-content-center mt-5 mb-5">
            {levelCard(strings.bachelors_programmes,"bachelors",bach)}
            {levelCard(strings.masters_programmes,"masters",masters)}
            {levelCard(strings.phd_programmes,"phd",phd)}
          </Row>
        </div>
      </div>
      <p 
        className="mb-5 mt-5"              
        style={{textAlign:"center" }}>
        <span className="fw-bolder" style={{fontSize: 30, textAlign:"center"}}>{strings.what_our_students_say}</span> 
      </p>
      <Row className=" justify-content-center mt-6 mb-5 mx-6">
        <Col sm={8} md={7} lg={7}    
          
              // className="text-dark lead my-4 mx-5" 
              style={{  textAlign:language=="en"?"left":"right",fontSize:"18px", padding: "auto", margin:"auto"}}>
              {strings.student1_story}
              {/* <span className="fw-bolder">Angela Buchi Gbandi,</span> Nigeria */}
              <br/>
              <br/>
              <Button as={Link} variant="secondary" className="animate-hover" to={Routes.OurStudents.path} style={{textDecoration:"none"}}>
                <FontAwesomeIcon icon={faChevronRight} className="animate-right-3 me-3 ms-2" />
                {strings.read_more}
              </Button>
          </Col>
          <Col sm={4} md={5} lg={5}   >
          <Image 
            src={male_1}//"https://cdn-clkbb.nitrocdn.com/yvdyHjvxnbyDWFOPGqIgfJuhVjswFevC/assets/static/optimized/rev-d42da31/wp-content/uploads/2015/07/gbandi.jpg"
            style={{height:"300px", width:"400px", objectFit: "cover", padding:0,margin:0, borderRadius:"15px"}}
            className= "shadow my-3"
          />
        </Col>
        </Row>
    </div>
  );
};
//  borderColor:"green", borderStyle:"solid",borderWidth:"5"