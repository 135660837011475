
import React , { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faCertificate, faChevronDown, faChevronLeft, faChevronRight, faHome, faHouseUser, faPiggyBank, faSchool } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Image, Button, Container, FormText, Form, Dropdown, ButtonGroup } from 'react-bootstrap';

import { Link,useLocation } from 'react-router-dom';

import { Routes } from "../../routes";

import disciplines from "../../data/disciplines";

import { useData } from "../../contexts/DataContext";

import { useHistory } from "react-router";

import useTranslation from "../../hooks/LocalizationHook";
import { useLocalization } from "../../contexts/LocalizationContext";


export default () => {
  const strings = useTranslation()
  const { language } = useLocalization();

  const countries = ["UK", "Australia", "Canada", "USA", "Malaysia", "turkey", "Hungary"];

  const [selectedLevel, setSelectedLevel] = useState("masters");
  const location = useLocation();
  // const whereRef = useRef()
  // const whatRef = useRef()
  
  const [discipline, setDiscipline] = useState()
  const [country, setCountry] = useState()
  const history = useHistory()

  useEffect(() => {
    setSelectedLevel(location.pathname.split("/")[location.pathname.split("/").length-1]);
    console.log(location.pathname.split("/")[location.pathname.split("/").length-1])
    window.scrollTo(0, 0)
  }, [])

  function DisciplineCard(title, id, Icon){
    const [isHovering, setHovering] = useState(false);
    const history = useHistory();

    function handleMouseHover() {
      setHovering(!isHovering);
    }
    function disciplineClicked() {
      history.push(`/discipline_details/${selectedLevel}/${id}`);
    }
    return (
      <div
        className= {`shadow mx-2 my-2 align-items-center justify-content-center ${isHovering?"bg-black":"bg-white"}`}
        onClick={disciplineClicked}
        onMouseEnter={handleMouseHover}
        onMouseLeave={handleMouseHover}
        style={{
          width:"15em", height:"15em",
          borderRadius:"15px",
          backgroundColor: "#00000000",
          cursor: "pointer"
        }}
      >
        <Icon
        className="mt-5 mb-4"
        style={{display:"block",  margin:"auto", width:"5em", height:"5em", fill:isHovering? "cyan":"rgb(40, 43, 63)", "object-fit": "fill"}}
        />
        {/* <FontAwesomeIcon 
          icon={faSchool} 
          color="cyan"
          className="mt-5 mb-4"
          style={{display:"block",  margin:"auto", width:"5em", height:"5em"}}
        /> */}
        <p 
          className={`text-${isHovering? "light":"dark"}`}
          style={{padding:"0", textAlign:"center"}}
          >
            <span className="fw-bolder" style={{fontSize: 18}}>{title}</span>
        </p>

      </div>
    )
  }

  function onSearch(e){
    e.preventDefault()
    history.push({
        pathname: Routes.Search.path,
        state: { country: country, discipline: discipline?.name ?? undefined},
    });
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-center mt-5">
        <Image 
            src = "https://images.unsplash.com/photo-1543269664-56d93c1b41a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
            // src="https://cdn-clkbb.nitrocdn.com/yvdyHjvxnbyDWFOPGqIgfJuhVjswFevC/assets/static/optimized/rev-d42da31/wp-content/uploads/2016/07/ForStudentsCover_2.jpg" 
            style={{width:"100%", height:"50vh", "object-fit": "cover"}}
            className= "shadow bg-white rounded"
        />
        <div
          className= "rounded"
          style={{
            width:"100%", height:"50vh",
            backgroundColor: "#00000088",
            position: "absolute"
          }}
        />
        <p 
            className="mt-5" 
            style={{position: "absolute",  textAlign:"center"}}>
            <span className="fw-bolder text-light" style={{fontSize: 40, paddingInline: 20}}>{strings.find_dream}</span> 
            <br/>
            <span className="text-light" style={{paddingInline: 20}}>{strings.discover}</span> 

            <br/>
            <Form className='d-flex mt-5' onSubmit={onSearch} style={{paddingInline: 20}}>
            <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic" size="md" style={{display:"inline", backgroundColor: "white", borderTopRightRadius:language==="en"?0:undefined, borderBottomRightRadius:language==="en"?0:undefined,borderTopLeftRadius:language!="en"?0:undefined, borderBottomLeftRadius:language!="en"?0:undefined,}}>
                    {discipline ? (language == "en"? discipline.name : discipline.arabicName ) : strings.what_to_study}{/*  <FontAwesomeIcon icon={faChevronDown}/> */}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                {disciplines.map((disc) => <Dropdown.Item key={disc.id} className="text-dark" style={{textDecoration:"none"}} eventKey={disc.id} onSelect={(e) => setDiscipline(disciplines.filter((item)=>item.id==e)[0])}>{language == "en"? disc.name : disc.arabicName}</Dropdown.Item>)}
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic" size="md" style={{display:"inline",  backgroundColor: "white", borderTopLeftRadius:0, borderBottomLeftRadius:0,borderTopRightRadius:0, borderBottomRightRadius:0}}>
                    {country??strings.where}{/*  <FontAwesomeIcon icon={faChevronDown}/> */}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                {countries.map((cont) => <Dropdown.Item key={cont} className="text-dark" style={{textDecoration:"none"}} eventKey={cont} onSelect={(e) => setCountry(cont)}>{strings[cont]}</Dropdown.Item>)}
                </Dropdown.Menu>
            </Dropdown>
            {/* <Form.Control
                type="dropdown"
                placeholder="What do you want to study?"
                name="discipline"
                style={{borderTopRightRadius:0, borderBottomRightRadius:0, maxWidth:300 }}
                // value={whatRef.current.value}
                ref={whatRef}
                required
                xs={1}
            /> */}
            {/* <Form.Control
                type="text"
                placeholder="Where"
                name="country"
                style={{borderTopLeftRadius:0, borderBottomLeftRadius:0,borderTopRightRadius:0, borderBottomRightRadius:0, maxWidth:300 }}
                // value={whereRef.current.value}
                ref={whereRef}
                required
            /> */}
            <Button variant="success" className="mx-0" type="submit"
            style={{borderTopLeftRadius:language==="en"?0:undefined, borderBottomLeftRadius:language==="en"?0:undefined, borderTopRightRadius:language!="en"?0:undefined, borderBottomRightRadius:language!="en"?0:undefined }}
            >
              {strings.search}
            </Button>
            </Form>
        </p>
      </div>
      <div className="d-flex align-items-center justify-content-center mt-5 mx-2">
        <div
          // className= "shadow bg-white rounded"
          className="mx-2"
          style={{
            width:"100%", minHeight:"30em",
            // backgroundColor: "#00000000",
          }}
        >
          <p 
          className="text-dark lead my-5 mx-3"
          style={{padding:"0", textAlign:"center"}}
          >
            <span className="fw-bolder" style={{fontSize: 30}}>{strings.browse_by_discipline}</span> 
          </p>
          <Row className="d-flex align-items-center justify-content-center mt-5 mb-5">
            {disciplines.map((disc)=>DisciplineCard(language == "en"? disc.name : disc.arabicName,disc.id,disc.icon))}
          </Row>
        </div>
      </div>
    </div>
  );
};
//  borderColor:"green", borderStyle:"solid",borderWidth:"5"