import React, { useContext, useState, useEffect } from "react";
import { firestore , appp} from "../firebase";
// import { useAuth } from "./AuthContext";
import disciplines from "../data/disciplines";
import { useLocalization } from "./LocalizationContext";
// import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';


const DataContext = React.createContext();

export function useData() {
  return useContext(DataContext);
}

export function DataProvider({ children }) {
    const [allProgrammes, setAllProgrammes] = useState([])

    const [selectedLevel, setSelectedLevel] = useState("masters")
    const [selectedDiscipline, setSelectedDiscipline] = useState(disciplines[0].id)
    const [selectedProgramme, setSelectedProgramme] = useState("")


    function setDiscipline(id){
        setSelectedDiscipline(id);
    }
    
    function setLevel(id){
        setSelectedLevel(id);
    }
    
    function setProgramme(id){
        setSelectedProgramme(id);
    }

    function getAllItemsFromCollection(collectionName, forceFetch){
        if(allProgrammes.length === 0 || forceFetch)
            getAllItems(collectionName, setAllProgrammes);
        return allProgrammes;
    }

    async function getProgramme(progId, language){
        let prog = await firestore.collection("programmes").doc(progId).get();
        return prog
    }

    async function getUniversity(id){
        return await firestore.collection("universities").doc(id).get();
    }
    // useEffect(() => {
    //     // setLoading(true);
    //     if(currentUser === null || privileges === "None")
    //         return;

    //     fetchAllData();
    // });

    // function fetchAllData(){
    //     getAllItems(collectionName, setAll);
    // }

    function getAllItems(collectionName, func){
        const cancelSub =  firestore.collection(collectionName)//.limit(1)
            .onSnapshot({
                next: snapshot => {
                  const docs = snapshot.docs
                        .sort((a, b) => a.get("lastModified") > b.get("lastModified") ? -1 : 1).map((d)=>{let dat = d.data(); dat["id"] = d.id; return dat;})
                    func(docs);
                }
            });
        return () => cancelSub();
    }

    async function getItemsInstant(collectionName, language){
        const docs = await (await firestore.collection(collectionName).get()).docs;
        const dats = docs.sort((a, b) => a.get("lastModified") > b.get("lastModified") ? -1 : 1).map((d)=>{let dat = d.data(); dat["id"] = d.id; return dat;})
        console.log(dats[0]);
        return dats//
    }

    const value = {
        getAllItemsFromCollection,
        getItemsInstant,
        setDiscipline,
        setLevel,
        setProgramme,
        getProgramme,
        getUniversity,
        selectedDiscipline,
        selectedLevel,
        selectedProgramme,
        allProgrammes
    };

    return (
    <DataContext.Provider value={value}>
        {children}
    </DataContext.Provider>
    );
}
