import { Nav, Navbar, NavDropdown, Container } from "react-bootstrap";
import Logo from "../assets/img/logo.webp";
import React, { useState } from 'react';
import { Routes } from "../routes";
import LocalizedStrings from "react-localization";
import { useLocalization } from "../contexts/LocalizationContext";
import useTranslation from "../hooks/LocalizationHook";
// import { Routes } from "../routes";


export default function NavigationBar() {
  const { language, setLanguage } = useLocalization();
  const strings = useTranslation();

  const [expanded, setExpanded] = useState(false)

  return (
    <Navbar collapseOnSelect expanded={expanded} className= "shadow p-3 mb-5 bg-white rounded" bg="light" expand="sm" variant="light" fixed="top" >
  <Container>
  <Navbar.Brand href="#">
        <img
          src={Logo}
        //   height="150"
          className="img-responsive"
          alt="ATeamSpot"
          style={{width: "100%", height: 50, }}
        />
    </Navbar.Brand>
    <Navbar.Brand href="#">
        ATeamSpot
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")}/>
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="me-auto">
        <Nav.Link onClick={() => setExpanded(false)} href={"#"+Routes.Home.path}>{strings.home}</Nav.Link>
        <NavDropdown title={strings.study} id="responsive-nav-dropdown">
          <NavDropdown.Item onClick={() => setExpanded(false)} href={"#"+Routes.ChooseLevel.path}>{strings.choose_academic_level}</NavDropdown.Item>
          <NavDropdown.Item onClick={() => setExpanded(false)} href={"#"+Routes.Search.path}>{strings.search_programmes}</NavDropdown.Item>
          {/* <NavDropdown.Divider /> */}
        </NavDropdown>
        <NavDropdown title={strings.about_as} id="responsive-nav-dropdown">
          <NavDropdown.Item onClick={() => setExpanded(false)} href={"#"+Routes.OurStory.path}>{strings.our_story}</NavDropdown.Item>
          <NavDropdown.Item onClick={() => setExpanded(false)} href={"#"+Routes.OurTeam.path}>{strings.our_team}</NavDropdown.Item>
          <NavDropdown.Item onClick={() => setExpanded(false)} href={"#"+Routes.OurStudents.path}>{strings.what_our_students_say}</NavDropdown.Item>
          {/* <NavDropdown.Divider /> */}
        </NavDropdown>
        <Nav.Link onClick={() => setExpanded(false)} href={"#"+Routes.Contact.path}>{strings.contact}</Nav.Link>

      </Nav>
    <Nav>
    {/* <Navbar.Collapse className="justify-content-end"> */}
      <Navbar.Text onClick={() => setExpanded(false)}>
        <a className= "mx-2" disabled={language=="ar"} onClick={()=>{
          setLanguage("ar");
        }}>AR</a> 
        <a className= "mx-2" disabled={language=="en"} onClick={()=>{
          setLanguage("en");
        }}>EN</a>
      </Navbar.Text>
    {/* </Navbar.Collapse> */}
    </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
  );
}
