
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Image, Button, Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import { Routes } from "../../routes";
import useTranslation from "../../hooks/LocalizationHook";
import { useLocalization } from "../../contexts/LocalizationContext";


export default () => {
  const {language} = useLocalization();

  return (
    <div>
        <div className="align-items-center justify-content-center mt-7 mx-5">
        {language!="ar" &&
        <p><span className="fw-bolder">1) General Terms and Conditions</span><br />
        a) Ambitious Agile LLC is a Malaysian company registered under Malaysian law and authorized to employ students in universities and academic institutes formally contracted with Ambitious Agile LLC to market its programs.
        b) All information and data provided by Ambitious Agile LLC to its potential or actual customers are exclusive data of the applicant and are held by law for Ambitious Agile LLC.
        c) Sharing offers, consultations and data submitted by Ambitious Agile LLC to its potential or actual customers with another person for practical or personal purposes without the knowledge or official approval of Ambitious Agile LLC is a breach of the privacy and rights of Ambitious Agile. Ambitious Agile LLC has the right to take the necessary measures towards that end.
        d) Recommendations and university nominations submitted to the student or the applicant are not limited to the universities recognized in the country of residence or nationality of the student or the applicant only. Ambitious Agile LLC has the right to submit recommendations or nominations to universities not recognized in the country of residence or nationality of the student Or the applicant in case the student, applicant, and / or the lack of specialization or academic program to be enrolled in the recognized universities in the country of residence or nationality of the student are not required. Knowing that our team is always working to provide university offers that ensure the student or the applicant a prosperous practical future.
        c) The costs shown in the offer may differ slightly from the tuition fees depending on the different USD to MYR exchange rate.<br />
        <span className="fw-bolder">2) Terms of privacy and confidentiality of customer datas</span><br />
        a) Information provided to Ambitious Agile LLC for use as part of the submission, acceptance and registration of Malaysian universities and institutes.
        b) Ambitious Agile LLC acknowledges not to use the data of its potential or actual clients for purposes other than the services provided (admission, registration, academic counselling, relocation and accommodation, travel and tourism) to the State of Malaysia.
        c) Ambitious Agile LLC acknowledges not to share or use the evidence of its potential or actual customers with a third party.
        d) In the event that one of the services provided by Ambitious Agile requires to share the data of its potential or actual customers for the purpose of providing a service through a third party (air tickets, housing contracts, deportation), the second party will be notified and approval will be obtained. Before sharing data.
        <br /><br />
        </p>}
        {language=="ar" &&
        <p dir="rtl"><span className="fw-bolder">1) &#1575;&#1604;&#1588;&#1585;&#1608;&#1591; &#1608;&#1575;&#1604;&#1571;&#1581;&#1603;&#1575;&#1605; &#1575;&#1604;&#1593;&#1575;&#1605;&#1577;</span><br />
        a)    شركة الطموح المرن ذ.م.م هي شركة ماليزية مسجلة بموجب القانون الماليزي ومصرحة لتوظيف الطلاب بالجامعات والمعاهد الدراسية المتعاقدة رسميا مع الطموح المرن ذ.م.م بهدف تسويق برامجها.
        b)    جميع المعلومات والبيانات المقدمة من شركة الطموح المرن ذ.م.م  لعملائها المحتملين او الفعلين تعتبر بيانات حصرية لمقدم الطلب ومحفوظة بموجب القانون لشركة الطموح المرن ذ.م.م.
        c)    مشاركة العروض والاستشارات والبيانات المقدمة من شركة الطموح المرن ذ.م.م  لعملائها المحتملين أو الفعلين مع شخص اخر بهدف عملي او شخصي من دون علم او موافقة رسمية من شركة الطموح المرن ذ.م.م  يعتبر خرق لخصوصية وحقوق شركة الطموح المرن ذ.م.م  ويحق لشركة الطموح المرن ذ.م.م  اتخاذ الاجراءات اللازمة اتجاه ذلك.
        d)    التوصيات والترشيحات الجامعية المقدمة للطالب او مقدم الطلب لا تنحصر على الجامعات المعترف بها في دولة اقامة او جنسية الطالب او مقدم الطلب فقط، ويحق لشركة الطموح المرن ذ.م.م تقديم توصيات أو ترشيحات جامعات غير معترف بها في دولة اقامة او جنسية الطالب او مقدم الطلب في حال لم يطلب ذلك من قبل الطالب او مقدم الطلب أو\وعدم توفر التخصص أو\والبرنامج الأكاديمي المراد الالتحاق به في الجامعات المعترف بها في دولة اقامة او جنسية الطالب. مع العلم ان فريقنا يعمل دوما على توفير عروض الجامعات التي تضمن للطالب او مقدم الطلب مستقبلا عمليا مزدهرا.
        <br />
        <span className="fw-bolder">2) &#1576;&#1606;&#1608;&#1583; &#1582;&#1589;&#1608;&#1589;&#1610;&#1577; &#1608;&#1587;&#1585;&#1610;&#1577; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1593;&#1605;&#1604;&#1575;&#1569;</span><br />
        a)    المعلومات التي يتم تزويدها لشركة الطموح المرن ذ.م.م للاستخدام في إطار التقديم والقبول والتسجيل للجامعات والمعاهد الدراسية الماليزية.
        b)    تقر شركة الطموح المرن ذ.م.م بعدم استخدام بيانات عملائها المحتملين أو\والمفعلين بغرض غير الخدمات المقدمة (القبول والتسجيل – الاستشارات الاكاديمية – الترحيل والسكن – السفر والسياحة) لدولة ماليزيا.
        c)    تقر شركة الطموح المرن ذ.م.م بعدم مشاركة او استخدام بيانات عملائها المحتملين أو الفعلين مع طرف ثالث.
        d)    في حال تطلب احد الخدمات المقدمة من قبل شركة الطموح المرن ذ.م.م لمشاركة بيانات عملائها المحتملين او\و الفعلين بغرض تقديم خدمة من خلال طرف ثالث (تذاكر الطيران – عقود السكن – الترحيل) سيتم إخطار الطرف الثاني و الحصول على موافقة قبل مشاركة البيانات.
        </p>}
        <br /><br />
        {/* <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:12pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1) General Terms and Conditions</span></p>
        <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a) Flexible Ambition LLC is a Malaysian company registered under Malaysian law and authorized to employ students in universities and academic institutes formally contracted with Flexible Flexibility LLC to market its programs.</span></p>
        <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b) All information and data provided by Flexible Ambition LLC to its potential or actual customers are exclusive data of the applicant and are held by law for Flexible Ambition LLC.</span></p>
        <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c) Sharing offers, consultations and data submitted by Flexible Flexibility LLC to its potential or actual customers with another person for practical or personal purposes without the knowledge or official approval of Flexible Flexibility LLC is a breach of the privacy and rights of Flexible Flexibility LLC. Flexible Ambition LLC has the right to take the necessary measures towards that end.</span></p>
        <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d) Recommendations and university nominations submitted to the student or the applicant are not limited to the universities recognized in the country of residence or nationality of the student or the applicant only. Flexible Company LLC has the right to submit recommendations or nominations to universities not recognized in the country of residence or nationality of the student Or the applicant in case the student, applicant, and / or the lack of specialization or academic program to be enrolled in the recognized universities in the country of residence or nationality of the student are not required. Knowing that our team is always working to provide university offers that ensure the student or the applicant a prosperous practical future.</span></p>
        <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c) The costs shown in the offer may differ slightly from the tuition fees depending on the different USD to MYR exchange rate.</span></p>
        <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2) Terms of privacy and confidentiality of customer data</span></p>
        <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">a) Information provided to Flexible Ambition LLC for use as part of the submission, acceptance and registration of Malaysian universities and institutes.</span></p>
        <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">b) Flexible Ambition LLC acknowledges not to use the data of its potential or actual clients for purposes other than the services provided (admission, registration, academic counselling, relocation and accommodation, travel and tourism) to the State of Malaysia.</span></p>
        <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">c) Flexible Ambition LLC acknowledges not to share or use the evidence of its potential or actual customers with a third party.</span></p>
        <p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">d) In the event that one of the services provided by Flexible Ambition LLC requires to share the data of its potential or actual customers for the purpose of providing a service through a third party (air tickets, housing contracts, deportation), the second party will be notified and approval will be obtained. Before sharing data.</span></p> */}
      </div>
      
    </div>
  );
};