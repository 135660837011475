
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Image, Button, Spinner, Row, Col, } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import { Routes } from "../../routes";
import programmes from "../../data/programmes";
import { ReactComponent as clock } from '../../assets/img/icons/wall-clock.svg';
import { ReactComponent as money } from '../../assets/img/icons/money.svg';
import { ReactComponent as telegram } from '../../assets/img/icons/telegram.svg';
import { ReactComponent as calendar } from '../../assets/img/icons/calendar.svg';
import { ReactComponent as bach } from '../../assets/img/icons/bach.svg';
import { ReactComponent as certificate } from '../../assets/img/icons/certificate.svg';
import { ReactComponent as book } from '../../assets/img/disciplines/book.svg';
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import SuggestedProgrammeCard from "../../components/SuggestedProgrammeCard";
import { useData } from "../../contexts/DataContext";
import { useLocation, useHistory } from 'react-router-dom'
import disciplines from "../../data/disciplines";

import moment from "moment-timezone";
import LocalizedStrings from "react-localization";
import useTranslation from "../../hooks/LocalizationHook";
import { useLocalization } from "../../contexts/LocalizationContext";


export default () => {
  const strings = useTranslation();
  const {language} = useLocalization();
  const defaultUniLink = "https://google.com"
  const { getAllItemsFromCollection, getUniversity} = useData()

  const [university, setUniversity] = useState({})

  const location = useLocation();
  const history = useHistory()

  const [UID, setUID] = useState()

  const [width, setWidth] = useState(window.innerWidth)

  
  useEffect(() => {
    const uniId = location.pathname.split("/")[location.pathname.split("/").length-1]
    setUID(uniId);
    fetchUniversity(uniId);

    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  function DisciplineCard(title, id, Icon){
    const [isHovering, setHovering] = useState(false);
    const history = useHistory();

    function handleMouseHover() {
      setHovering(!isHovering);
    }
    function disciplineClicked() {
      console.log("disc clicked ",id)
      history.push({
        pathname: Routes.Search.path,
        state: { university: UID, discipline: id},
      });    
    }
    return (
      <div
        className= {`shadow mx-2 my-2 align-items-center justify-content-center ${isHovering?"bg-black":"bg-white"}`}
        onClick={disciplineClicked}
        onMouseEnter={handleMouseHover}
        onMouseLeave={handleMouseHover}
        style={{
          width:"15em", height:"15em",
          borderRadius:"15px",
          backgroundColor: "#00000000",
          cursor: "pointer"
        }}
      >
        <Icon
        className="mt-5 mb-4"
        style={{display:"block",  margin:"auto", width:"5em", height:"5em", fill:isHovering? "cyan":"rgb(40, 43, 63)", "object-fit": "fill"}}
        />
        {/* <FontAwesomeIcon 
          icon={faSchool} 
          color="cyan"
          className="mt-5 mb-4"
          style={{display:"block",  margin:"auto", width:"5em", height:"5em"}}
        /> */}
        <p 
          className={`text-${isHovering? "light":"dark"}`}
          style={{padding:"0", textAlign:"center"}}
          >
            <span className="fw-bolder" style={{fontSize: 18}}>{title}</span>
        </p>

      </div>
    )
  }

  async function fetchUniversity(uniId){
    if(uniId === ""){
        history.push(Routes.NotFound.path); 
        return;
    }

    window.scrollTo(0, 0)

    const uni = await getUniversity(uniId);

    if(!uni){
        history.push(Routes.NotFound.path);
        return;
    }

    console.log(uni)

    setUniversity(uni.data())
  }
  function detailItem(title, text, Icon){
    return (
      <Col
        className= "d-flex align-items-center justify-content-center"
        style={{
          // width:"20em", 
          borderStyle:"solid2"
        }}
      >
        {/* <FontAwesomeIcon icon={icon} /> */}
        <Icon
        style={{  marginInline: "20px",width:"40px", height:"40px", fill:"rgb(24,121,156)", "object-fit": "fill"}}
        />

        <p 
          className="text-dark"
          style={{padding:"0", margin:"auto 0px", textAlign:language=="en"?"left":"right", borderStyle:"solid3"}}
          >
            <span className="fw-bolder" style={{fontSize: 22}}>{title}</span>
            <br/>
            {text}
        </p>

      </Col>
    )
  }
  return (
    <>{!university ? <div className="vh-100 d-flex align-items-center justify-content-center">
    <Spinner animation="border" role="status" style={{left: "50%", top:"50%"}}>
  <span className="visually-hidden">Loading...</span>
</Spinner>
    </div> : <div>
      <div 
        className="d-flex align-items-center justify-content-center mt-5"
        style={{position:"relative"}}
      >
        <Image 
            src={university.cover} 
            style={{width:"100%", height:"70vh", "object-fit": "cover"}}
            className= "shadow bg-white rounded"
        />
        
      </div>
      {/* <div className="d-flex align-items-center justify-content-center mt-6 mb-5" style={{borderStyle:"solid2"}}> */}
        <div className="d-flex my-5" style={{borderStyle:"solid2", marginTop:30}}>
        <div
               className="d-flex text-dark "
              style={{ width: "50%",marginInline: "7.5%", textAlign:language=="en"?"left":"right", display:"block", borderStyle:"solid2"}}>
              <img
              className={`mt-${width>1100?0:5}`}
              src={university.icon}
              style={{ paddingLeft:"0px",  margin:"0", width: 100, height:100, objectFit: "cover",}}
              />
              <p
              className={`text-dark mt-${width>1100?0:5}`}
              style={{marginInline:"20px", marginBlock:"auto", textAlign:language=="en"?"left":"right",}}
              >
              <a className="fw-bolder" style={{fontSize: 20, textDecoration:"none"}} onClick={()=>{if(university.link) window.open(university.link, "_blank")}}>{language=="en"?university.name:university.nameArabic ?? university.name}</a>
              <span style={{fontSize: 15,display:"block", paddingTop:"-5px",}}>{language=="en"?university.city:university.cityArabic ?? university.city}, {language=="en"?university.country:university.countryArabic ?? university.country}</span>
              {/* <br/>
              attendance, language, ielts score, courses, gpa, living cost */}
              </p>
          </div>
          <Row
                className="shadow d-flex align-items-center justify-content-center"
                style={
                  width > 1100 ?
                  {
                    width:"35em",
                    height: "5em",
                    backgroundColor: "#ffffff",
                    position:"absolute",
                    borderRadius:"15px",
                    // marginTop:"50%",
                    right:language=="en"?"5%":undefined,
                    left:language!="en"?"5%":undefined,
                    textAlign:"right"
                  } : {
                    width:"100%",
                    minHeight: "5em",
                    backgroundColor: "#ffffff",
                    position:"absolute",
                    borderRadius:"15px",
                    marginTop:"-6em",
                    // right:"7.5%",
                    textAlign:"right",
                    marginInline: "auto"
                  }}
            >
            {detailItem(university.ranking, strings.ranking, certificate)}
            {detailItem(university.programmes ?? 0 , strings.programmes, book)}
            {detailItem(university.students ?? 0, strings.students, bach)}
        </Row>
        </div>
         <p 
            className="text-dark lead my-4" 
            style={{ width: "85%",padding:10, textAlign:language=="en"?"left":"right", display:"block", margin:"0 auto"}}>
            <span className="fw-bolder" style={{fontSize: 25}}>{strings.overview}</span> 
            <br/>
            <br/>
            {language=="en"?university.overview:university.overviewArabic ?? university.overview}
        </p>
       {/* <div 
            style={{
                width: "1px",
                backgroundColor: "#00000055",
                margin: "0 40px",
                height: 120,
            }}
            >
            </div> */}
        
        {/* </div> */}
        <div className="align-items-center justify-content-center mt-5 mx-2 lead">
        
      </div>
      <div className="d-flex align-items-center justify-content-center mt-5 mx-2">
        <div
          // className= "shadow bg-white rounded"
          className="mx-2"
          style={{
            width:"100%", minHeight:"30em",
            // backgroundColor: "#00000000",
          }}
        >
          <p 
          className="text-dark lead my-5 mx-2"
          style={{padding:"0", textAlign:"center"}}
          >
            <span className="fw-bolder" style={{fontSize: 22}}>{strings.browse_programmes_in}  {language=="en"?university.name:university.nameArabic ?? university.name} {strings.by_discipline}</span> 
          </p>
          <Row className="d-flex align-items-center justify-content-center mt-5 mb-5">
            {disciplines.map((disc)=>DisciplineCard(language=="en"?disc.name:disc.arabicName,disc.id,disc.icon))}
          </Row>
        </div>
      </div>
      <div className="align-items-center justify-content-center mt-3 mb-5"
          style={{textAlign:"center"}}
      >
      <Button as={Link} variant="secondary" className="animate-hover" onClick={()=>{
          history.push({
            pathname: Routes.Search.path,
            state: { university: UID},
          });  
        }} style={{textDecoration:"none"}}>
        <FontAwesomeIcon icon={faChevronRight} className="animate-right-3 me-3 ms-2" />
        {strings.see_all_programmes}
      </Button>
      </div>
    </div>}</>
  );
};
//  borderColor:"green", borderStyle:"solid",borderWidth:"5"